import {call, put, takeLatest, select, all} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {ADD_TABLE_CART, COMPLETE_DINEIN_BOOKING, UPDATE_AND_PRINT_TABLE_CART} from './constants';
import {removeItemByBookingId, setTableCartBookingId} from './actions';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {
  makeSelectHotel,
  makeSelectToken,
  makeSelectUser,
  makeSelectWalkInUser,
} from '../../Pages/Authentication/selectors';
import printOfflineBooking from '../../Libs/printOfflineBooking';
import {unauthorized} from '../../Pages/Authentication/actions';
import {makeSelectCheckoutMode, makeSelectProducts, makeSelectRecentProductItems} from './selectors';
import {checkIfObjectEmpty, reformatTelephoneNumber} from '../../Libs/object';
import {makeSelectCustomer, makeSelectDiscount, makeSelectOperation, makeSelectSelectedNotes} from '../Cart/selectors';
import {filterItemsByTableId} from '../../Libs/filterItemsByTableId';
import {makeSelectTableId, makeSelectTables} from '../../Pages/DineIn/selectors';
import {cartTotal, getDeliveryCharges, getDiscountPrice, getMinOrderAmount} from '../../Libs/prices';
import {makeSelectDineInChange, makeSelectDineInPayments} from '../../Pages/DineInCheckout/selectors';
import {
  createBooking as createBookingTrigger,
  createBookingError,
  createBookingSuccess,
} from '../../Pages/Bookings/actions';
import {clearDineInPayment} from '../../Pages/DineInCheckout/actions';

export function* createBooking() {
  const token = yield select(makeSelectToken());
  const pendingProducts = yield select(makeSelectProducts());
  const recentProducts = yield select(makeSelectRecentProductItems());
  const customer = yield select(makeSelectCustomer());
  const walkInUser = yield select(makeSelectWalkInUser());
  const user = yield select(makeSelectUser());
  const tableId = yield select(makeSelectTableId());
  const tables = yield select(makeSelectTables());
  const {branch} = yield select(makeSelectHotel());
  const mode = yield select(makeSelectCheckoutMode());
  const operation = yield select(makeSelectOperation());
  const notes = yield select(makeSelectSelectedNotes());
  const discount = yield select(makeSelectDiscount());
  const payments = yield select(makeSelectDineInPayments());
  const change = yield select(makeSelectDineInChange());

  let filterItems = filterItemsByTableId(pendingProducts, tableId);

  const minOrderAmount = getMinOrderAmount(branch, mode);
  const cartTotalAmount = cartTotal(filterItems?.products, mode);
  let bookingId = filterItems?.bookingId;
  const subTotal = minOrderAmount > cartTotalAmount ? minOrderAmount : cartTotalAmount;
  const discountedPrice = getDiscountPrice(discount, subTotal);
  const deliveryCharges = getDeliveryCharges(operation, mode, subTotal);

  let tableNameFilter = tables.filter((table) => table.id == tableId);

  let tableName = '';

  if (tableNameFilter.length > 0) {
    tableName = tableNameFilter?.[0]?.name;
  }

  let formData = {};

  formData = checkIfObjectEmpty(customer)
    ? {
        ...walkInUser,
        telephone: reformatTelephoneNumber(walkInUser.telephone),
      }
    : {...customer, telephone: reformatTelephoneNumber(customer.telephone)};

  let obj = {
    booking_id: bookingId ? bookingId : null,
    floor_id: filterItems?.floorId,
    table_id: filterItems?.tableId,
    clockin_user_id: user?.id,
    branch_slug: branch?.slug,
    checkout_mode: mode,
    sub_total: subTotal,
    discount_amount: discountedPrice,
    delivery_charges: deliveryCharges,
    payments,
    formData: {
      ...formData,
      telephone: reformatTelephoneNumber(formData.telephone),
    },
    notes: notes.length ? notes.join(', ') : '',
    cart: filterItems?.products,
    bookingUnique: uuidv4(),
    syncState: 'syncing',
    checkout_time: moment().format('YY-MM-DD HH:mm'),
  };

  const requestUrl = `${apiUrl}/pos/bookings/posGenerateDinInBooking`;
  try {
    const booking = yield call(apiCall, requestUrl, AuthorizedPostHeaders(obj, token));

    if (!checkIfObjectEmpty(booking)) {
      let recentProductPrint = [];

      recentProducts.map((product) => {
        let obj = booking?.booking_products?.find((row) => row?.product_id == product.id);
        recentProductPrint.push(obj);
      });

      const customerData = checkIfObjectEmpty(customer) ? branch?.walk_in_user?.walk_in_user_address : customer;
      let receiptNotes = notes.length ? notes.join(', ') : '';
      printOfflineBooking(
        customerData,
        receiptNotes,
        change,
        booking,
        recentProductPrint,
        branch,
        true,
        false,
        tableName,
      );

      let tableId = booking?.booking?.table_id;

      if (tableId) {
        yield put(setTableCartBookingId(tableId, booking?.booking?.id));
      }
      yield put(createBookingSuccess(obj?.bookingUnique, booking));
    } else {
      if (bookingId) {
        yield put(removeItemByBookingId(bookingId));
      }
      yield put(clearDineInPayment());
    }
  } catch (e) {
    console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(createBookingError(e.message, obj.bookingUnique));
    }
  }
}

export function* completeBooking(action) {
  const token = yield select(makeSelectToken());

  const {branch} = yield select(makeSelectHotel());
  const change = yield select(makeSelectDineInChange());

  const requestUrl = `${apiUrl}/pos/bookings/posGenerateDinInBooking`;
  try {
    const booking = yield call(apiCall, requestUrl, AuthorizedPostHeaders(action?.bookingObj, token));

    let recentProductPrint = [];

    printOfflineBooking(
      action?.bookingObj?.formData,
      action?.bookingObj?.notes,
      change,
      booking,
      recentProductPrint,
      branch,
      true,
      true,
    );

    yield put(createBookingSuccess(action?.bookingObj?.bookingUnique, booking));
    yield put(removeItemByBookingId(action?.bookingObj?.booking_id));
    yield put(clearDineInPayment());
  } catch (e) {
    console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(createBookingError(e.message, action?.bookingObj?.bookingUnique));
    }
  }
}

function* createBookingSaga() {
  yield takeLatest(ADD_TABLE_CART, createBooking);
}

function* updateBookingSaga() {
  yield takeLatest(UPDATE_AND_PRINT_TABLE_CART, createBooking);
}

function* completeBookingSaga() {
  yield takeLatest(COMPLETE_DINEIN_BOOKING, completeBooking);
}

export default function* bookingsSaga() {
  yield all([createBookingSaga(), updateBookingSaga(), completeBookingSaga()]);
}
