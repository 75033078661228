import React from 'react';
import {Button} from 'reactstrap';
import {useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import {makeSelectHotel} from '../Pages/Authentication/selectors';
import {
  makeSelectCheckoutMode,
  makeSelectCustomer,
  makeSelectDiscount,
  makeSelectSelectedNotes,
  makeSelectProducts,
  makeSelectOperation,
} from './Cart/selectors';
import {cartTotal, getDeliveryCharges, getDiscountPrice} from '../Libs/prices';
import printOfflineBooking from '../Libs/printOfflineBooking';
import {checkIfObjectEmpty} from '../Libs/object';
import {makeSelectDefaultPrinter, makeSelectPrinters} from '../Pages/Management/selectors';
import {makeSelectChange, makeSelectPayments} from '../Pages/Checkout/selectors';

function Print() {
  const {branch} = useSelector(makeSelectHotel());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const printers = useSelector(makeSelectPrinters());
  const mode = useSelector(makeSelectCheckoutMode());
  const payments = useSelector(makeSelectPayments());
  const notes = useSelector(makeSelectSelectedNotes());
  const products = useSelector(makeSelectProducts());
  const customer = useSelector(makeSelectCustomer());
  const discount = useSelector(makeSelectDiscount());
  const change = useSelector(makeSelectChange());
  const operation = useSelector(makeSelectOperation());
  const subTotal = cartTotal(products, mode);
  const discountPrice = getDiscountPrice(discount, subTotal);
  const deliveryCharges = getDeliveryCharges(operation, mode, subTotal);
  const onClickPrint = async () => {
    if (products.length > 0) {
      const formData = checkIfObjectEmpty(customer) ? branch.walk_in_user?.walk_in_user_address : customer;
      await printOfflineBooking(
        formData,
        notes,
        change,
        {
          branch_slug: branch.slug,
          checkout_mode: mode,
          sub_total: subTotal,
          discount_amount: discountPrice,
          delivery_charges: deliveryCharges,
          payments,
          formData,
          notes: notes.length ? notes.join(', ') : '',
          cart: products,
          bookingUnique: uuidv4(),
          syncState: 'syncing',
          checkout_time: moment().format('YY-MM-DD HH:mm'),
        },
        [],
        branch,
        false,
        false,
      );
    }
  };
  return (
    <Button className="action-button bg-info" onClick={onClickPrint}>
      <i className="fa fa-print" />
      <span>PRINT</span>
    </Button>
  );
}

export default Print;
