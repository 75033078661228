import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, Redirect, useHistory} from 'react-router-dom';
import {Row} from 'reactstrap';
import {fromJS} from 'immutable';
import {map} from 'lodash';
import Master from '../Master';
import {makeSelectCheckoutMode, makeSelectProducts} from '../../Components/Cart/selectors';
import {getProductById} from '../../Libs/menu';
import {makeSelectMenu} from '../../Selectors/Menu';
import EditTier from './EditTier';
import {getProperPrice, productPrice} from '../../Libs/prices';
import Price from '../../Components/Price';
import {updateCartItem} from '../../Components/Cart/actions';
import {addAddonToTier, getExtraTiers, getSelectedClickable} from '../../Libs/product';
import Clickable from './Clickable';
import {checkIfObjectEmpty} from '../../Libs/object';

function EditProduct() {
  const {index} = useParams();
  const dispatch = useDispatch();
  const {push} = useHistory();
  const mode = useSelector(makeSelectCheckoutMode());
  const products = useSelector(makeSelectProducts());
  const menu = useSelector(makeSelectMenu());
  const selected = products[parseInt(index, 10)];
  const product = useMemo(() => getProductById(menu, selected?.id), [menu, selected?.id]);
  const [item, setItem] = useState(fromJS({...selected, clickable: {}}));
  useEffect(() => {
    const clickable = getSelectedClickable(item.get('tiers').toJS(), product.specific_tiers, product.clickables);
    setItem(item.set('clickable', fromJS(clickable)));
  }, []);
  if (!selected) {
    return <Redirect to="/dashboard" />;
  }

  const updateOption = (option, tierIndex) => {
    const itemPrice = getProperPrice(option, item.getIn(['tiers', 0]).toJS());
    setItem(
      item.setIn(['tiers', tierIndex, 'selected'], {
        ...option,
        quantity: 1,
        price: itemPrice,
      }),
    );
  };

  const updateSize = (size, tierIndex) => {
    const itemPrice = getProperPrice(size);
    setItem(
      item.setIn(['tiers', tierIndex, 'selected'], {
        ...size,
        quantity: 1,
        price: itemPrice,
      }),
    );
  };

  const updateTierProduct = (p, tierIndex) => {
    const itemPrice = getProperPrice(p, item.getIn(['tiers', 0]).toJS());
    if (!checkIfObjectEmpty(item.getIn(['tiers', tierIndex])?.toJS())) {
      setItem(
        item.setIn(['tiers', tierIndex, 'selected'], {
          ...p,
          quantity: 1,
          price: itemPrice,
        }),
      );
    } else {
      const itemDetails = product.specific_tiers[tierIndex - product.tiers.length];
      const tier = fromJS({
        id: itemDetails.id,
        type: itemDetails.type,
        product_id: itemDetails.product_id,
        type_id: itemDetails.type_id,
        title: itemDetails.title,
        basket_title: itemDetails.basket_title,
        free_items: itemDetails.free_items,
        max_items: itemDetails.max_items,
        required: itemDetails.required,
        priority: itemDetails.priority,
        selected: {
          ...p,
          quantity: 1,
          price: itemPrice,
        },
      });
      setItem(item.setIn(['tiers', tierIndex], tier));
    }
  };

  const addAddon = (addon, tierIndex) => {
    let tier;
    if (!item.getIn(['tiers', tierIndex])) {
      const itemDetails = product.specific_tiers[tierIndex - product.tiers.length];
      tier = fromJS({
        id: itemDetails.id,
        type: itemDetails.type,
        product_id: itemDetails.product_id,
        type_id: itemDetails.type_id,
        title: itemDetails.title,
        basket_title: itemDetails.basket_title,
        free_items: itemDetails.free_items,
        max_items: itemDetails.max_items,
        required: itemDetails.required,
        priority: itemDetails.priority,
        selected: {free: [], paid: []},
      });
    } else {
      tier = item.getIn(['tiers', tierIndex]);
    }
    const updatedAddons = addAddonToTier(tier, addon, item.getIn(['tiers', 0]).toJS());
    setItem(item.setIn(['tiers', tierIndex], updatedAddons));
  };

  const removeAddon = (tierIndex, tag, addonIndex) => {
    setItem(item.deleteIn(['tiers', tierIndex, 'selected', tag, addonIndex]));
  };

  const handleClickable = (clickable) => {
    setItem(item.set('clickable', fromJS(clickable)));
  };

  const handleRemoveClickable = (e) => {
    e.preventDefault();
    const clickable = item.get('clickable').toJS();
    const extraTiers = getExtraTiers(product.specific_tiers, clickable);
    const tierIds = map(extraTiers, 'id');
    setItem(
      item
        .set('clickable', fromJS({}))
        .update('tiers', (selectedTiers) => selectedTiers.filter((tier) => !tierIds.includes(tier.get('id')))),
    );
  };

  const handleAddToCart = () => {
    dispatch(updateCartItem(item, index));
    push('/dashboard');
  };

  const extraTiers = getExtraTiers(product.specific_tiers, item.get('clickable')?.toJS());

  return (
    <Master>
      <div className="container-fluid customer-container edit-product">
        <div className="d-flex justify-content-between px-1">
          <div className="">
            <div>{item.get('title')}</div>
          </div>
          <div className="">
            <Price>{productPrice(item.toJS(), mode)}</Price>
          </div>
        </div>
        {product.tiers.map((tier, i) => (
          <div key={tier.id}>
            <Row className="mx-1 bg-dark text-light mb-2 py-2 pl-3 box-shadow">
              <strong>{tier.title}</strong>
            </Row>
            <Row className="tier-wrapper">
              <EditTier
                tier={tier}
                size={item.getIn(['tiers', 0]).toJS()}
                selected={item.getIn(['tiers', i])}
                updateOption={(option) => updateOption(option, i)}
                updateSize={(size) => updateSize(size, i)}
                updateProduct={(p) => updateTierProduct(p, i)}
                addAddon={(addon) => addAddon(addon, i)}
                removeAddon={(tag, addonIndex) => removeAddon(i, tag, addonIndex)}
              />
            </Row>
          </div>
        ))}
        <Clickable
          handleClickable={handleClickable}
          clickables={product.clickables}
          selectedClickable={item.get('clickable')?.toJS()}
          handleRemoveClickable={handleRemoveClickable}
        />
        {extraTiers.map((tier, i) => (
          <div key={tier.id}>
            <Row className="mx-1 bg-dark text-light mb-2 py-2 pl-3 box-shadow">
              <strong>{tier.title}</strong>
            </Row>
            <Row className="tier-wrapper">
              <EditTier
                tier={tier}
                size={item.getIn(['tiers', 0]).toJS()}
                selected={item.getIn(['tiers', product.tiers.length + i])}
                updateOption={(option) => updateOption(option, product.tiers.length + i)}
                updateSize={(size) => updateSize(size, product.tiers.length + i)}
                updateProduct={(p) => updateTierProduct(p, product.tiers.length + i)}
                addAddon={(addon) => addAddon(addon, product.tiers.length + i)}
                removeAddon={(tag, addonIndex) => removeAddon(product.tiers.length + i, tag, addonIndex)}
              />
            </Row>
          </div>
        ))}
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary px-5 py-3 my-3" type="button" onClick={handleAddToCart}>
            <h3 className="m-0 fs-13">DONE</h3>
          </button>
        </div>
      </div>
    </Master>
  );
}

export default EditProduct;
