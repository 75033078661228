import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectCheckoutMode} from './Cart/selectors';
import {toggleCheckout} from './Cart/actions';

function CheckoutMode() {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const checkoutMode = useSelector(makeSelectCheckoutMode());
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckoutMode = (mode) => {
    if (mode === 'Delivery') {
      push('/customer');
    }
    dispatch(toggleCheckout(mode));
  };

  const renderCheckoutMode = (mode) => {
    switch (mode) {
      case 'Delivery':
        return 'Delivery';
      case 'Dine-in':
        return 'DINE IN';
      default:
        return 'Collection';
    }
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown">
        <button type="button" onClick={toggle} className="cart-header">
          {renderCheckoutMode(checkoutMode)}
        </button>
      </DropdownToggle>
      <DropdownMenu className="toggle-wrapper">
        <DropdownItem className="checkout-toggle" onClick={() => handleCheckoutMode('Takeaway')}>
          COLLECTION
        </DropdownItem>
        <DropdownItem className="checkout-toggle" onClick={() => handleCheckoutMode('Delivery')}>
          DELIVERY
        </DropdownItem>

        <DropdownItem className="checkout-toggle" onClick={() => handleCheckoutMode('Dine-in')}>
          DINE IN
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default CheckoutMode;
