import {fromJS} from 'immutable';
import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
  SET_SINGE_MENU,
  SET_MENU_CHILDRENS,
  SET_SINGE_SUB_MENU,
  SET_ITEM,
  SET_CART_ITEM,
  SET_CLICKABLE,
} from '../Constants';
import {CLOCKOUT} from '../Pages/Authentication/constants';

const INITIAL_STATE = fromJS({
  loading: false,
  success: false,
  error: false,
  status: '',
  menu: [],
  menuChildrens: [],
  selectedMenu: {
    id: null,
    products: [],
  },
  selectedSubMenu: {
    id: null,
    products: [],
  },
  selectedItem: {
    id: null,
    tiers: [],
  },
  cartItem: {
    id: null,
    tiers: [],
  },
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MENU:
      return state.set('state', 'loading').set('status', '');
    case FETCH_MENU_SUCCESS:
      return state.set('menu', fromJS(action.menu)).set('state', 'success');
    case FETCH_MENU_ERROR:
      return state.set('status', action.status).set('state', 'error');
    case SET_SINGE_MENU:
      return state
        .set('selectedMenu', fromJS(action.selectedMenu))
        .set(
          'selectedSubMenu',
          fromJS({
            id: null,
            products: [],
          }),
        )
        .set('menuChildrens', fromJS([]));
    case SET_MENU_CHILDRENS:
      return state.set('menuChildrens', fromJS(action.childrens));
    case SET_SINGE_SUB_MENU:
      return state.set('selectedSubMenu', fromJS(action.selectedMenu));
    case SET_ITEM: {
      const itemDetails = action.selectedItem;

      const item = {
        id: itemDetails.id,
        title: itemDetails.title,
        description: itemDetails.description,
        slug: itemDetails.slug,
        price: itemDetails.price,
        image: itemDetails.image,
        tiers: [],
        hold: [],
        clickable: {},
      };

      return state.set('selectedItem', fromJS(action.selectedItem)).set('cartItem', fromJS(item));
    }
    case SET_CLICKABLE:
      return state
        .setIn(['cartItem', 'clickable'], fromJS(action.clickable))
        .updateIn(['cartItem', 'tiers'], (tiers) => tiers.concat(fromJS(action.extraTiers)));
    case CLOCKOUT:
      return INITIAL_STATE;
    // case ADD_HOLD_ITEM: {
    //   const index = state
    //     .getIn(['cartItem', 'hold'])
    //     .findIndex((item) => item === action.item);
    //   if (index === -1) {
    //     return state.updateIn(['cartItem', 'hold'], (items) =>
    //       items.push(action.item),
    //     );
    //   }
    //   return state.deleteIn(['cartItem', 'hold', index]);
    // }
    case SET_CART_ITEM:
      return state.setIn(['cartItem', 'tiers', action.index], action.tier);
    default:
      return state;
  }
};
