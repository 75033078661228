export const TOGGLE_TABLE_CHECKOUT = 'Favorite/Cart/TOGGLE_TABLE_CHECKOUT';

export const ADD_TABLE_CUSTOMER = 'Favorite/Cart/ADD_TABLE_CUSTOMER';

export const ADD_TO_TABLE_CART = 'Favorite/Cart/ADD_TO_TABLE_CART';

export const ADD_TABLE_CART = 'Favorite/Cart/ADD_TABLE_CART';

export const UPDATE_AND_PRINT_TABLE_CART = 'Favorite/Cart/UPDATE_AND_PRINT_TABLE_CART';

export const SET_TABLE_CART_BOOKING_ID = 'Favorite/Cart/SET_TABLE_CART_BOOKING_ID';

export const INCREASE_TABLE_CART_QUANTITY = 'Favorite/Cart/INCREASE_TABLE_CART_QUANTITY';

export const DECREASE_TABLE_CART_QUANTITY = 'Favorite/Cart/DECREASE_TABLE_CART_QUANTITY';

export const REMOVE_TABLE_CART_ITEM = 'Favorite/Cart/REMOVE_TABLE_CART_ITEM';

export const CLEAR_TABLE_CART = 'Favorite/Cart/CLEAR_TABLE_CART';

export const ADD_TABLE_DISCOUNT = 'Favorite/Cart/ADD_TABLE_DISCOUNT';

export const REMOVE_TABLE_DISCOUNT = 'Favorite/Cart/REMOVE_TABLE_DISCOUNT';

export const ADD_TABLE_PAYMENT = 'Favorite/Cart/ADD_TABLE_PAYMENT';

export const REMOVE_TABLE_PAYMENT = 'Favorite/Cart/REMOVE_TABLE_PAYMENT';

export const ADD_TABLE_NOTES = 'Favorite/Cart/ADD_TABLE_NOTES';

export const REMOVE_TABLE_NOTES = 'Favorite/Cart/REMOVE_TABLE_NOTES';

export const SELECT_TABLE_ITEM = 'Favorite/Cart/SELECT_TABLE_ITEM';

export const ADD_HOLD_TABLE_ITEMS = 'Favorite/Cart/ADD_HOLD_TABLE_ITEMS';

export const ADD_TABLE_EXTRAS = 'Favorite/Cart/ADD_TABLE_EXTRAS';

export const UPDATE_TABLE_CART_ITEM = 'Favorite/Cart/UPDATE_TABLE_CART_ITEM';

export const SAVE_TABLE_OPERATION = 'Favorite/Cart/SAVE_TABLE_OPERATION';

export const COMPLETE_DINEIN_BOOKING = 'Favorite/Cart/COMPLETE_DINEIN_BOOKING';

export const REMOVE_ITEM_BY_BOOKING_ID = 'Favorite/Cart/REMOVE_ITEM_BY_BOOKING_ID';
