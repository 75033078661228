import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NumpadKey from './NumpadKey';
import NumpadDisplay from './NumpadDisplay';
import {makeSelectDineInSpliting} from './selectors';

const Numpad = ({remaining, displayValue, setDisplayValue, setSpliting}) => {
  const dispatch = useDispatch();
  const spliting = useSelector(makeSelectDineInSpliting());

  useEffect(() => {
    setDisplayValue(remaining);
  }, [remaining]);

  const clearAll = () => {
    setDisplayValue('0');
  };

  const clearDisplay = () => {
    setDisplayValue('0');
  };

  const inputDot = () => {
    if (!/\./.test(displayValue)) {
      setDisplayValue(`${displayValue}.`);
    }
  };

  const inputDigit = (digit) => {
    if (setSpliting !== false) {
      dispatch(setSpliting(false));
    }
    setDisplayValue(displayValue === '0' || spliting ? String(digit) : displayValue + digit);
  };

  return (
    <div className="numpad">
      <NumpadDisplay selected={spliting} value={displayValue} />
      <div className="numpad-keypad">
        <div className="input-keys">
          <div className="digit-keys">
            <NumpadKey className="key-0" onPress={() => inputDigit(0)}>
              0
            </NumpadKey>
            <NumpadKey className="key-dot" onPress={() => inputDot()}>
              ●
            </NumpadKey>
            <NumpadKey className="key-1" onPress={() => inputDigit(1)}>
              1
            </NumpadKey>
            <NumpadKey className="key-2" onPress={() => inputDigit(2)}>
              2
            </NumpadKey>
            <NumpadKey className="key-3" onPress={() => inputDigit(3)}>
              3
            </NumpadKey>
            <NumpadKey className="key-4" onPress={() => inputDigit(4)}>
              4
            </NumpadKey>
            <NumpadKey className="key-5" onPress={() => inputDigit(5)}>
              5
            </NumpadKey>
            <NumpadKey className="key-6" onPress={() => inputDigit(6)}>
              6
            </NumpadKey>
            <NumpadKey className="key-7" onPress={() => inputDigit(7)}>
              7
            </NumpadKey>
            <NumpadKey className="key-8" onPress={() => inputDigit(8)}>
              8
            </NumpadKey>
            <NumpadKey className="key-9" onPress={() => inputDigit(9)}>
              9
            </NumpadKey>
          </div>
        </div>
        <div className="operator-keys">
          <NumpadKey className="key-10" onPress={() => inputDigit(10)}>
            10
          </NumpadKey>
          <NumpadKey className="key-10" onPress={() => inputDigit(20)}>
            20
          </NumpadKey>
          <NumpadKey className="key-10" onPress={() => inputDigit(50)}>
            50
          </NumpadKey>

          <NumpadKey className="key-clear bg-warning" onPress={() => (clearDisplay ? clearDisplay() : clearAll())}>
            AC
          </NumpadKey>
        </div>
      </div>
    </div>
  );
};

export default Numpad;
