import React from 'react';
import moment from 'moment';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Price from '../../Components/Price';
import {
  bookingNotesToNotes,
  bookingPaymentsToPayments,
  bookingProductsToProducts,
  paidStatus,
} from '../../Libs/bookings';
import {editBooking, setBookingId} from './actions';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {addDiscount, saveOperation} from '../../Components/Cart/actions';
import {makeSelectToken} from '../Authentication/selectors';
import {makeSelectProducts} from '../../Components/Cart/selectors';
import {deformatTelephoneNumber} from '../../Libs/object';
import {calculateVoucherDiscount, getDeliveryCharges} from '../../Libs/prices';

function SyncedBooking({booking}) {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const products = useSelector(makeSelectProducts());
  const token = useSelector(makeSelectToken());

  const fetchDeliveryOperations = async (postcode, branchId) => {
    apiCall(`${apiUrl}/pos/delivery-charges/${postcode}/${branchId}`, AuthorizedGetHeaders(token))
      .then((operation) => {
        dispatch(saveOperation(operation));
        const deliveryCharges = getDeliveryCharges(
          operation,
          booking.booking.checkout_mode,
          booking.booking.total_charges,
        );
        const voucherDiscount = calculateVoucherDiscount(
          booking.booking.sub_total_charges,
          booking.booking.total_charges,
          deliveryCharges,
        );
        if (voucherDiscount > 0) {
          const discount = {
            amount: voucherDiscount || 0,
            type: 'fixed',
            title: 'voucher discount',
          };
          dispatch(addDiscount(discount));
        }
      })
      .catch((e) => {
        console.log('delivery charges error', e);
      });
  };

  const handlePrepareEdit = () => {
    dispatch(setBookingId(booking?.booking?.id));

    if (products.length > 0) {
      alert('Clear cart before editing previous order');
      return;
    }
    const cart = bookingProductsToProducts(booking.booking_products);
    const payments = bookingPaymentsToPayments(booking.payment);
    const discount = {
      amount: booking.booking.discount_amount || 0,
      type: 'fixed',
      title: 'pre applied',
    };
    const notes = bookingNotesToNotes(booking.booking.notes);
    fetchDeliveryOperations(booking.user_address.postcode, booking.booking.branch_id);
    dispatch(
      editBooking(
        cart,
        payments,
        discount,
        notes,
        {
          ...booking.user_address,
          telephone: deformatTelephoneNumber(booking.user_address.telephone),
        },
        booking.booking.checkout_mode,
        booking.booking.id,
      ),
    );
    push('/checkout');
  };
  return (
    <div className="booking">
      <div className="booking-info">
        <button type="button" onClick={handlePrepareEdit} className="platform">
          <span className={`platform-button ${booking.booking_mode?.mode === 'web' ? 'bg-info' : 'bg-dark'} `}>
            {booking.booking_mode ? booking.booking_mode.mode : booking.checkout_platform}
          </span>
        </button>
        <div className="address-details">
          <div className="address">{booking.user_address.address}</div>
          <div className="postcode">{booking.user_address.postcode}</div>
          <div className="order-id">{booking.user_address?.telephone}</div>
        </div>
      </div>
      <div className="payment-info">
        <div className="amount">
          <Price>{booking.booking.total_charges}</Price>
        </div>
        <div className="status">{paidStatus(booking.payment)}</div>
      </div>
      <div className="time-info">
        <p className="status">
          <strong className={booking.booking.checkout_mode?.toLowerCase()}>
            {booking.booking.checkout_mode === 'Delivery' ? 'Delivery' : 'Collection'}
          </strong>
        </p>
        <p className="value">{moment(booking.booking.created_at).format('YYYY-MM-DD HH:mm')}</p>
      </div>
      <Link to={`/update-booking/${booking.booking.id}`} className="payment-method">
        <p>VIEW</p>
      </Link>
    </div>
  );
}

export default SyncedBooking;
