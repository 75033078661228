import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'connected-react-router/immutable';
import {persistStore, autoRehydrate} from 'redux-persist-immutable';
// eslint-disable-next-line import/no-extraneous-dependencies
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import {createStateSyncMiddleware, initStateWithPrevTab} from 'redux-state-sync';
import {fromJS} from 'immutable';

import {
  ADD_NOTES,
  SELECT_ITEM,
  CLEAR_CART,
  REMOVE_NOTES,
  REMOVE_PAYMENT,
  ADD_PAYMENT,
  REMOVE_DISCOUNT,
  ADD_DISCOUNT,
  TOGGLE_CHECKOUT,
  ADD_CUSTOMER,
  ADD_TO_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  REMOVE_ITEM,
  ADD_HOLD_ITEMS,
  ADD_EXTRAS,
} from '../Components/Cart/constants';

import createReducer from './Reducers';
import rootSaga from './Sagas';
import history from '../Libs/history';
import {CREATE_BOOKING} from '../Pages/Bookings/constants';

let persistedStore = {};

const config = {
  prepareState: (state) => state.toJS(),
  whitelist: [
    ADD_NOTES,
    SELECT_ITEM,
    CLEAR_CART,
    REMOVE_NOTES,
    REMOVE_PAYMENT,
    ADD_PAYMENT,
    REMOVE_DISCOUNT,
    ADD_DISCOUNT,
    TOGGLE_CHECKOUT,
    ADD_CUSTOMER,
    ADD_TO_CART,
    DECREASE_QUANTITY,
    INCREASE_QUANTITY,
    REMOVE_ITEM,
    ADD_HOLD_ITEMS,
    ADD_EXTRAS,
    CREATE_BOOKING,
  ],
};

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const middlewares = [sagaMiddleware, routerMiddleware(), createStateSyncMiddleware(config)];

  const enhancers = [applyMiddleware(...middlewares), autoRehydrate()];

  const store = createStore(createReducer(history), fromJS(initialState), composeWithDevTools(...enhancers));

  initStateWithPrevTab(store);

  store.runSaga = sagaMiddleware.run(rootSaga);

  persistedStore = persistStore(store, {
    whitelist: [
      'menu',
      'auth',
      'cart',
      'bookings',
      'customer',
      'dineIn',
      'notes',
      'checkout',
      'drivers',
      'tasks',
      'till',
      'management',
      'tableCart',
    ],
    version: 3,
  });
  // const keys = ['cart'];

  // persistedStore.purge(['management']);

  return {store, persistedStore};
}

export const persistor = persistedStore;
