import {fromJS} from 'immutable';

import {
  SET_DINEIN_CHANGE,
  SPLIT_DINEIN_ORDER,
  SET_DINEIN_PAYMENT,
  SET_DINEIN_DISPLAY_VALUE,
  REMOVE_DINEIN_PAYMENT,
  SET_DINEIN_PAYMENTS,
  SET_DINEIN_SPLITING,
  CLEAR_DINEIN_PAYMENT,
} from './constants';
import {CLEAR_TABLE_CART} from '../../Components/PendingOrders/constants';
import {EDIT_BOOKING} from '../Bookings/constants';

const initialState = fromJS({
  change: 0,
  displayValue: '0',
  payments: [],
  splitOrder: false,
  spliting: false,
});

export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DINEIN_CHANGE:
      return state.set('change', action.change);
    case SET_DINEIN_PAYMENT:
      return state.update('payments', (payments) => payments.push(fromJS(action.payment))).set('change', action.change);
    case REMOVE_DINEIN_PAYMENT:
      return state.deleteIn(['payments', action.index]);
    case SET_DINEIN_PAYMENTS:
      return state.set('payments', fromJS(action.payments)).set('change', action.change);
    case SET_DINEIN_DISPLAY_VALUE:
      return state.set('displayValue', action.value);
    case SPLIT_DINEIN_ORDER:
      return state.set('splitOrder', action.status).set('spliting', action.status);
    case SET_DINEIN_SPLITING:
      return state.set('spliting', action.status);
    case CLEAR_DINEIN_PAYMENT:
      return initialState;
    case EDIT_BOOKING:
      return state.set('payments', fromJS(action.payments));
    case CLEAR_TABLE_CART:
      return initialState;
    default:
      return state;
  }
}
