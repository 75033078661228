import {
  GET_FLOOR,
  GET_FLOOR_SUCCESS,
  GET_FLOOR_ERROR,
  GET_TABLES,
  GET_TABLES_SUCCESS,
  GET_TABLES_ERROR,
  SET_TABLE_ID,
  RESET_TABLES,
} from './constants';

export const getFloors = () => ({
  type: GET_FLOOR,
});

export const getFloorsSuccess = (floors) => ({
  type: GET_FLOOR_SUCCESS,
  floors,
});

export const getFloorsError = (status) => ({
  type: GET_FLOOR_ERROR,
  status,
});

export const resetTables = () => ({
  type: RESET_TABLES,
});

export const getTables = (floorId) => {
  return {
    type: GET_TABLES,
    floorId,
  };
};

export const getTablesSuccess = (tables) => ({
  type: GET_TABLES_SUCCESS,
  tables,
});

export const getTablesError = (status) => ({
  type: GET_TABLES_ERROR,
  status,
});

export const setTableId = (tableId) => {
  return {
    type: SET_TABLE_ID,
    tableId,
  };
};
