import React from 'react';
import Master from '../Master';
import ProductList from '../../Components/ProductList';

import PendingOrders from '../../Components/PendingOrders';
import SubProductList from '../../Components/SubProductList';

const Dashboard = () => {
  return (
    <Master>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <ProductList />
        <SubProductList />
        <PendingOrders />
      </div>
    </Master>
  );
};

export default Dashboard;
