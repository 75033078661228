import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Col, FormGroup, Label, Alert} from 'reactstrap';
import {useParams} from 'react-router-dom';
import Row from 'reactstrap/es/Row';
import {useDispatch, useSelector} from 'react-redux';
import {addDiscount} from '../../Components/PendingOrders/actions';
import {makeSelectUser} from '../Authentication/selectors';
import {makeSelectDiscount} from '../../Components/PendingOrders/selectors';
import {checkIfObjectEmpty} from '../../Libs/object';
import Master from '../Master';
import TextInput from '../../Components/TextInput';
import validateDiscount from '../../Libs/validateDiscount';
import {toCents} from '../../Libs/prices';

function DineInDiscounts() {
  const {push} = useHistory();
  const {bookingId} = useParams();
  const dispatch = useDispatch();
  const discount = useSelector(makeSelectDiscount());
  const {pos_discounts} = useSelector(makeSelectUser());
  const [title] = useState('Custom');
  const [type, setType] = useState('');
  const [validated, setValidated] = useState('validated');
  const [amount, setAmount] = useState('');
  const handleDiscount = (d) => {
    if (d.title === discount.title) {
      dispatch(addDiscount({}));
    } else {
      dispatch(addDiscount(bookingId, d));
    }
  };

  const handleCreateDiscount = () => {
    const details = {
      title,
      type,
      amount: type === 'percentage' ? amount : toCents(amount),
    };
    setValidated('validated');
    const validation = validateDiscount(details);
    setValidated(validation);
    if (validation === 'validated') {
      dispatch(addDiscount(bookingId, details));
      push('/checkout-dine-in');
    }
  };
  return (
    <Master>
      <div className="container-fluid customer-container" id="discounts">
        <Row>
          <Col>
            {!checkIfObjectEmpty(discount) ? (
              <>
                <Row className="selected-discount">
                  <p>Selected Discount:&nbsp;</p>
                  <h4>
                    <strong>{discount.title}</strong>
                  </h4>
                  <button type="button" onClick={() => dispatch(addDiscount({}))} className="remove">
                    REMOVE
                  </button>
                </Row>
                <hr />
              </>
            ) : null}
            <Row>
              <Col className="terminals">
                {pos_discounts?.map((d) => (
                  <Button
                    key={d.id}
                    onClick={() => handleDiscount(d)}
                    className={`terminal-button p-3 ml-2 ${discount.title === d.title ? 'active' : ''}`}
                    color="light"
                    size="sm"
                  >
                    {d.title}
                  </Button>
                ))}
              </Col>
            </Row>
          </Col>
          <Col className="create-discount">
            <Row>
              <h5>Create Discount</h5>
            </Row>
            <Row>
              <Col className="discount-types">
                <div className={`type ${type === 'percentage' && 'active'}`} onClick={() => setType('percentage')}>
                  Percentage
                </div>
                <div className={`type ${type === 'fixed' && 'active'}`} onClick={() => setType('fixed')}>
                  Fixed
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="m-0">
                  <Label>Amount</Label>
                  <TextInput
                    autoComplete="off"
                    inputClassName="form-control"
                    type="text"
                    value={amount}
                    onChange={(value) => setAmount(value)}
                    placeholder="Discount Amount"
                  />
                </FormGroup>
              </Col>
            </Row>
            {validated !== 'validated' && (
              <Row>
                <Col md="12">
                  <Alert>{validated}</Alert>
                </Col>
              </Row>
            )}
            <Button className="create-discount-btn" color="light" size="sm" onClick={handleCreateDiscount}>
              ADD DISCOUNT
            </Button>
          </Col>
        </Row>
      </div>
    </Master>
  );
}

export default DineInDiscounts;
