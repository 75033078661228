import {createSelector} from 'reselect';

export const selectCheckout = (state) => state.get('dineInCheckout');

export const makeSelectDineInPayments = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('payments').toJS());

export const makeSelectDineInSplitOrder = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('splitOrder'));

export const makeSelectDineInDisplayValue = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('displayValue'));

export const makeSelectDineInChange = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('change'));

export const makeSelectDineInSpliting = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('spliting'));
