import React, {useEffect} from 'react';
import {Row} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import ProductItem from './ProductItem';
import Loader from './Loader';
import {makeSelectSelectedMenu, makeSelectSelectedSubMenu} from '../Selectors/Menu';
import {selectItem} from './Cart/actions';

export default function SubProductList() {
  const dispatch = useDispatch();

  const selectedSubMenu = useSelector(makeSelectSelectedSubMenu());

  useEffect(() => {
    dispatch(selectItem(-1));
  });
  // if ((selectedSubMenu.products?.length || 0) === 0) {
  //   return (
  //     <div className="container-fluid customer-container">
  //       <h1>Items not found.</h1>
  //     </div>
  //   );
  // }

  if (selectedSubMenu?.products?.length > 0) {
    return selectedSubMenu.products.length > 0 ? (
      <div className="container-fluid customer-container">
        <Row>
          <ProductItem category={selectedSubMenu.slug} productItems={selectedSubMenu.products} />
        </Row>
      </div>
    ) : (
      <div className="container-fluid product-container">
        <Loader />
      </div>
    );
  } else {
    return <div></div>;
  }
}
