import React, {useState} from 'react';
import {v4 as uid} from 'uuid';
import {Row, ListGroup, ListGroupItem} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, Redirect} from 'react-router-dom';
import Price from '../Price';
import {cartTotal, priceByMode, productPrice} from '../../Libs/prices';
import {makeSelectProducts, makeSelectSelectedIndex} from './selectors';
import {checkIfObjectEmpty} from '../../Libs/object';
import {removeItem, selectItem, updateAndPrintTableCart} from './actions';
import {makeSelectClockedIn} from '../../Pages/Authentication/selectors';
import {makeSelectCheckoutMode} from '../Cart/selectors';
import {makeSelectTableId} from '../../Pages/DineIn/selectors';
import {filterItemsByTableId} from '../../Libs/filterItemsByTableId';

const SideBar = ({fullHeight}) => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const [, setShowButton] = useState(true);
  const isClockedIn = useSelector(makeSelectClockedIn());
  const products = useSelector(makeSelectProducts());
  const mode = useSelector(makeSelectCheckoutMode());

  const tableId = useSelector(makeSelectTableId());
  const selectedIndex = useSelector(makeSelectSelectedIndex());

  let filterProducts = filterItemsByTableId(products, tableId);

  const cartTotalAmount = cartTotal(filterProducts?.products, mode);

  const removeItemHandler = (parentIndex, index) => {
    dispatch(removeItem(parentIndex, index));
    setShowButton(true);
    dispatch(selectItem(-1));
  };

  const handleUpdateOrder = () => {
    dispatch(updateAndPrintTableCart());
    setShowButton(false);
    push('/dashboard');
  };

  const handleCompleteOrder = () => {
    push(`/checkout-dine-in`);
  };

  const navigateToEdit = (parentIndex, index) => {
    push(`/edit-table-cart/${parentIndex}/${index}`);
  };

  const renderCartItems = () => {
    return products.map((product, parentIndex) => {
      if (product.tableId == tableId) {
        return product?.products.map((item, i) => {
          return (
            <ListGroupItem key={uid()} className="py-1">
              <div className="cart-actions-container">
                <div className="cart-actions">
                  <div
                    className="trash-icon"
                    onClick={() => {
                      setShowButton(true);
                      removeItemHandler(parentIndex, i);
                    }}
                  >
                    <i className="fas fa-trash" />
                  </div>
                  {item?.tiers.length > 0 ? (
                    <div
                      className="edit-icon"
                      onClick={() => {
                        setShowButton(true);
                        navigateToEdit(parentIndex, i);
                      }}
                    >
                      <i className="fas fa-edit" />
                    </div>
                  ) : null}
                </div>

                {/* <div className="cart-quantity">
            <i className="fas fa-plus-circle plus-icon" onClick={() => updateQuantity(i, product)} />
            <input
              type="text"
              className="form-control input-number"
              defaultValue={product.quantity}
              min="1"
              max="100"
            />
            <i className="fas fa-minus-circle minus-icon btn-danger" onClick={() => removeQuantity(i, product)} />
          </div> */}
              </div>
              <Row className="m-0">
                <div className="cart-content" onClick={() => dispatch(selectItem(i, item))}>
                  <div
                    className={`row m-0 justify-content-between align-items-center px-1 ${
                      selectedIndex === i ? 'cart-active' : ''
                    }`}
                  >
                    <small style={{fontSize: '0.7rem', fontWeight: '500'}}>
                      {item.title} : <Price>{productPrice(item, mode)}</Price>
                    </small>
                  </div>
                  <Row className="m-0">
                    <ul className="tree-view m-0 px-1">
                      {item?.tiers.length > 0 &&
                        item?.tiers.map((tier) => {
                          if (checkIfObjectEmpty(tier)) {
                            return null;
                          }
                          if (tier.type === 'Addon') {
                            return (
                              <div className="tier" key={tier.id + i}>
                                <h2 className="tier-title">{tier.basket_title}</h2>
                                <ul className="tier-value addon">
                                  {tier.selected.free &&
                                    tier.selected.free.map((addon) => (
                                      <li key={addon.id}>
                                        <strong>{addon.quantity}x</strong> {addon.title} <strong>(free)</strong>
                                      </li>
                                    ))}
                                  {tier.selected.paid &&
                                    tier.selected.paid.map((addon) => (
                                      <li key={addon.id}>
                                        <strong>{addon.quantity}x</strong> {addon.title} {'  '}
                                        <strong>
                                          <Price>{priceByMode(addon.price, mode)}</Price>
                                        </strong>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            );
                          }
                          return (
                            <li key={uid()}>
                              <b>{tier.basket_title}</b> : {tier.selected.title} {'  '}
                              <strong>
                                <Price>{priceByMode(tier.selected.price, mode)}</Price>
                              </strong>
                            </li>
                          );
                        })}

                      {item?.extras?.length > 0 && (
                        <div className="tier">
                          <h2 className="tier-title">Extras</h2>
                          <ul className="tier-value addon">
                            {item?.extras?.map((item, index) => (
                              <li key={item.id}>
                                {item.title}{' '}
                                {item?.hold.length > index ? (
                                  <strong>(free)</strong>
                                ) : (
                                  <strong>
                                    (<Price>{item.price}</Price>)
                                  </strong>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {item?.hold?.length > 0 && (
                        <div className="tier">
                          <h2 className="tier-title">Hold</h2>
                          <ul className="tier-value addon">
                            {item?.hold?.map((item) => (
                              <li key={item}>{item} </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </ul>
                  </Row>
                </div>
              </Row>
            </ListGroupItem>
          );
        });
      }
    });
  };

  if (!isClockedIn) {
    return <Redirect to="/clock-in" />;
  }

  if (mode === 'Dine-in') {
    return (
      <div className={`pending-orders-container ${fullHeight ? 'fullHeight' : ''} `}>
        <div>
          <h6>PENDING ORDERS</h6>
          <ListGroup flush>
            {products.length > 0 ? (
              renderCartItems()
            ) : (
              <ListGroupItem>
                <center>
                  <small>NO ITEM IN CART..</small>
                </center>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>

        {filterProducts && (
          <div className="cart-footer-container" style={{position: 'relative'}}>
            <div className="sub-total">
              <span style={{color: 'white'}}>SUB TOTAL</span>
              <span className="subAmount">
                <Price>{cartTotalAmount}</Price>
              </span>
            </div>
          </div>
        )}

        <div>
          {filterProducts ? (
            <button onClick={() => handleUpdateOrder()} className="checkout-btn bg-danger" color="success" size="sm">
              UPDATE ORDER
            </button>
          ) : null}
        </div>
        {filterProducts && (
          <div>
            <button onClick={() => handleCompleteOrder()} className="checkout-btn bg-success" color="success" size="sm">
              COMPLETE ORDER
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default SideBar;
