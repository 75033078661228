import React from 'react';
import {Col} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Price from './Price';
import {onlyPriceWithDiscount} from '../Libs/prices';
import {makeSelectCheckoutMode} from './Cart/selectors';
import {setSelectedItem} from '../Actions/Menu';

export default function ProductItem({productItems, category}) {
  const dispatch = useDispatch();
  const mode = useSelector(makeSelectCheckoutMode());
  const {push} = useHistory();

  const setSelectedItemHandler = (item) => {
    dispatch(setSelectedItem(item));
    push(`/product/${category}/${item.id}`);
  };

  let updatedMode = null;

  if (mode == 'Delivery') {
    updatedMode = 'Delivery';
  } else if (mode == 'Takeaway') {
    updatedMode = 'Takeaway';
  } else if (mode == 'Dine-in') {
    updatedMode = 'Dinein';
  }

  if (mode === 'Dine-in') {
    return productItems.map((product) => {
      if (product.visible_in == 'dinein') {
        return (
          <div
            key={product.id}
            className="pl-2"
            style={{marginBottom: 10}}
            onClick={() => setSelectedItemHandler(product)}
          >
            <div className="prod-list">
              <div className="prod-title">
                <h6>{product.title}</h6>
              </div>
              <div className="prod-price">
                <strong>
                  <Price>{onlyPriceWithDiscount(product, mode)}</Price>
                </strong>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  } else {
    return productItems.map((product) => {
      if (product.visible_in === updatedMode.toLowerCase() || product.visible_in === 'both') {
        return (
          <div
            key={product.id}
            className="pl-2"
            style={{marginBottom: 10}}
            onClick={() => setSelectedItemHandler(product)}
          >
            <div className="prod-list">
              <div className="prod-title">
                <h6>{product.title}</h6>
              </div>
              <div className="prod-price">
                <strong>
                  <Price>{onlyPriceWithDiscount(product, mode)}</Price>
                </strong>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  }
}
