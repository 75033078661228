import {
  ADD_CUSTOMER,
  TOGGLE_CHECKOUT,
  ADD_TO_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  REMOVE_ITEM,
  ADD_DISCOUNT,
  REMOVE_DISCOUNT,
  ADD_PAYMENT,
  REMOVE_PAYMENT,
  ADD_NOTES,
  REMOVE_NOTES,
  CLEAR_CART,
  SELECT_ITEM,
  ADD_EXTRAS,
  ADD_HOLD_ITEMS,
  UPDATE_CART_ITEM,
  SAVE_OPERATION,
  ADD_CART,
} from './constants';

export const addTocart = (item) => {
  return {
    type: ADD_TO_CART,
    item,
  };
};

export const updateCartItem = (item, index) => ({
  type: UPDATE_CART_ITEM,
  item,
  index,
});

export const increaseQuantity = (index, quantity) => {
  return {
    type: INCREASE_QUANTITY,
    index,
    quantity,
  };
};

export const decreaseQuantity = (index, quantity) => {
  return {
    type: DECREASE_QUANTITY,
    index,
    quantity,
  };
};

export const removeItem = (index) => {
  return {
    type: REMOVE_ITEM,
    index,
  };
};

export const toggleCheckout = (mode) => ({
  type: TOGGLE_CHECKOUT,
  mode,
});

export const addCustomer = (customer) => ({
  type: ADD_CUSTOMER,
  customer,
});

export const addDiscount = (discount) => ({
  type: ADD_DISCOUNT,
  discount,
});

export const removeDiscount = () => ({
  type: REMOVE_DISCOUNT,
});

export const addPayment = (payment) => ({
  type: ADD_PAYMENT,
  payment,
});

export const removePayment = () => ({
  type: REMOVE_PAYMENT,
});

export const addNotes = (notes) => ({
  type: ADD_NOTES,
  notes,
});

export const removeNotes = () => ({
  type: REMOVE_NOTES,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const selectItem = (index, product) => {
  return {
    type: SELECT_ITEM,
    product,
    index,
  };
};

export const addHoldItems = (items, index) => ({
  type: ADD_HOLD_ITEMS,
  items,
  index,
});

export const addExtras = (items, index) => ({
  type: ADD_EXTRAS,
  items,
  index,
});

export const saveOperation = (operation) => ({
  type: SAVE_OPERATION,
  operation,
});

export const addCart = (cart) => ({
  type: ADD_CART,
  cart,
});
