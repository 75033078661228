import {fromJS} from 'immutable';

import {
  GET_FLOOR,
  GET_FLOOR_SUCCESS,
  GET_FLOOR_ERROR,
  GET_TABLES,
  GET_TABLES_SUCCESS,
  GET_TABLES_ERROR,
  SET_TABLE_ID,
  RESET_TABLES,
} from './constants';

const initialState = fromJS({
  floors: [],
  tables: [],
  floorId: '',
  tableId: '',
});

export default function dineInReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLOOR:
      return state.set('state', 'loading').set('status', '');
    case GET_FLOOR_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case GET_FLOOR_SUCCESS:
      return state.set('state', 'success').set('floors', fromJS(action.floors));
    case RESET_TABLES:
      return state.set('tables', fromJS([]));
    case GET_TABLES:
      return state.set('state', 'loading').set('status', '').set('floorId', action.floorId);
    case GET_TABLES_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case GET_TABLES_SUCCESS:
      return state.set('state', 'success').set('tables', fromJS(action.tables));
    case SET_TABLE_ID:
      return state.set('tableId', action.tableId);
    default:
      return state;
  }
}
