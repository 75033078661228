import {fromJS} from 'immutable';
import {
  ADD_TO_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  REMOVE_ITEM,
  ADD_CUSTOMER,
  TOGGLE_CHECKOUT,
  ADD_DISCOUNT,
  REMOVE_DISCOUNT,
  ADD_PAYMENT,
  REMOVE_PAYMENT,
  CLEAR_CART,
  ADD_NOTES,
  REMOVE_NOTES,
  SELECT_ITEM,
  ADD_EXTRAS,
  ADD_HOLD_ITEMS,
  UPDATE_CART_ITEM,
  SAVE_OPERATION,
  ADD_CART,
} from './constants';
import {ADD_NOTE, REMOVE_NOTE} from '../../Pages/Notes/constants';
import {EDIT_BOOKING} from '../../Pages/Bookings/constants';
import {FIND_CUSTOMER_SUCCESS} from '../../Pages/Customer/constants';

const initialState = fromJS({
  loading: false,
  success: false,
  error: false,
  status: '',
  products: [],
  checkoutMode: 'Dine-in',
  customer: {},
  discount: {},
  payment: {},
  notes: [],
  selectedIndex: -1,
  selectedProduct: {},
  operation: {},
  bookingMode: 'generate',
  bookingId: -1,
});

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return state.update('products', (products) => products.unshift(action.item));
    case ADD_CART:
      return state.set('products', fromJS(action.cart));
    case UPDATE_CART_ITEM:
      return state.setIn(['products', action.index], action.item);
    case INCREASE_QUANTITY:
      return state.setIn(['products', action.index, 'quantity'], action.quantity);
    case DECREASE_QUANTITY:
      return state.setIn(['products', action.index, 'quantity'], action.quantity);
    case REMOVE_ITEM:
      return state.deleteIn(['products', action.index]);
    case ADD_CUSTOMER:
      return state.set('customer', fromJS(action.customer));
    case TOGGLE_CHECKOUT:
      return state.set('checkoutMode', action.mode);
    case ADD_DISCOUNT:
      return state.set('discount', fromJS(action.discount));
    case REMOVE_DISCOUNT:
      return state.set('discount', fromJS({}));
    case ADD_PAYMENT:
      return state.set('payment', fromJS(action.payment));
    case REMOVE_PAYMENT:
      return state.set('payment', fromJS({}));
    case ADD_NOTES:
      return state.set('notes', action.notes);
    case REMOVE_NOTES:
      return state.set('notes', '');
    case SELECT_ITEM: {
      if (action.index === -1) {
        return state.set('selectedIndex', -1).set('selectedProduct', fromJS({}));
      }
      if (state.get('selectedIndex') === action.index) {
        return state.set('selectedIndex', -1).set('selectedProduct', fromJS({}));
      }
      return state.set('selectedIndex', action.index).set('selectedProduct', fromJS(action.product));
    }
    case ADD_HOLD_ITEMS:
      return state.setIn(['products', action.index, 'hold'], fromJS(action.items));
    case ADD_EXTRAS:
      return state.setIn(['products', action.index, 'extras'], fromJS(action.items));
    case ADD_NOTE:
      return state.update('notes', (notes) => notes.push(action.note));
    case REMOVE_NOTE:
      return state.deleteIn(['notes', action.index]);
    case SAVE_OPERATION:
      return state.set('operation', fromJS(action.operation));
    case EDIT_BOOKING:
      return state
        .set('products', fromJS(action.products))
        .set('bookingMode', 'edit')
        .set('discount', fromJS(action.discount))
        .set('customer', fromJS(action.customer))
        .set('checkoutMode', action.checkoutMode)
        .set('notes', fromJS(action.notes))
        .set('bookingId', action.bookingId);
    case FIND_CUSTOMER_SUCCESS: {
      if (action.products.length > 0) {
        return state.set('products', fromJS(action.products));
      }
      return state;
    }
    case CLEAR_CART:
      return initialState;
    default:
      return state;
  }
}
