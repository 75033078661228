const filterItemsByTableId = (products, tableId) => {
  let filterProducts = products?.length > 0 ? products.filter((product) => product.tableId == tableId) : [];

  if (filterProducts?.length > 0) {
    return filterProducts[0];
  } else {
    return false;
  }
};

export {filterItemsByTableId};
