import {createSelector} from 'reselect';

export const selectDineIn = (state) => state.get('dineIn');

export const makeSelectFloors = () => createSelector(selectDineIn, (DineInState) => DineInState.get('floors').toJS());

export const makeSelectTables = () => createSelector(selectDineIn, (DineInState) => DineInState.get('tables').toJS());

export const makeSelectFloorId = () => createSelector(selectDineIn, (DineInState) => DineInState.get('floorId'));

export const makeSelectTableId = () => createSelector(selectDineIn, (DineInState) => DineInState.get('tableId'));

export const makeSelectState = () => createSelector(selectDineIn, (DineInState) => DineInState.get('state'));

export const makeSelectStatus = () => createSelector(selectDineIn, (DineInState) => DineInState.get('status'));
