import React from 'react';
import {ConnectedRouter} from 'connected-react-router/immutable';
import {Route, Switch, Router} from 'react-router-dom';

import {useSelector} from 'react-redux';
import history from '../Libs/history';
import Login from './Authentication/Login';
import ClockIn from './Authentication/ClockIn';
import Dashboard from './Dashboard';
import {makeSelectHydrated} from '../Redux/selectors';
import Loader from '../Components/Loader';
import Customer from './Customer';
import Checkout from './Checkout';
import Bookings from './Bookings';
import ClockOut from './ClockOut';
import Hold from './Hold';
import Extras from './Extras';
import CustomerView from './CustomerView';
import Notes from './Notes';
import UpdateBookingStatus from './UpdateBookingStatus';
import EditProduct from './EditProduct';
import Product from './Product';
import Tasks from './Tasks';
import Home from './Home';
import Sales from './Sales';
import Members from './Members';
import TillManagement from './TillManagement';
import Customers from './Customers';
import UpdateSystem from './UpdateSystem';
import Management from './Management';
import Discounts from './Discounts';
import DineInPage from './DineIn/dineIn';
import DineInCheckout from './DineInCheckout';
import EditProductTableCart from './EditProductTableCart';
import DineInDiscounts from './DineInDiscounts';

const Root = () => {
  const hydrated = useSelector(makeSelectHydrated());
  if (hydrated) {
    return (
      <ConnectedRouter history={history}>
        <Router history={history}>
          <Switch>
            <Route path="/dine-in" component={DineInPage} />
            <Route path="/update-booking/:id" component={UpdateBookingStatus} />
            <Route path="/edit/:index" component={EditProduct} />
            <Route path="/edit-table-cart/:parentIndex/:index" component={EditProductTableCart} />
            <Route exact path="/product/:category/:id" component={Product} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/customer" component={Customer} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/checkout-dine-in" component={DineInCheckout} />
            <Route exact path="/orders" component={Bookings} />
            <Route exact path="/clock-out" component={ClockOut} />
            <Route exact path="/discounts" component={Discounts} />
            <Route exact path="/dine-in-discounts/:bookingId" component={DineInDiscounts} />
            <Route exact path="/hold" component={Hold} />
            <Route exact path="/extras" component={Extras} />
            <Route exact path="/notes" component={Notes} />
            <Route path="/customers" component={Customers} />
            <Route exact path="/customer-view" component={CustomerView} />
            <Route exact path="/clock-in" component={ClockIn} />
            <Route exact path="/update-system" component={UpdateSystem} />
            <Route path="/tasks" component={Tasks} />
            <Route path="/login" component={Login} />
            <Route path="/sales" component={Sales} />
            <Route path="/members" component={Members} />
            <Route path="/management" component={Management} />
            <Route exact path="/till-management" component={TillManagement} />
            <Route path="/" component={Home} />
          </Switch>
        </Router>
      </ConnectedRouter>
    );
  }
  return <Loader />;
};

export default Root;
