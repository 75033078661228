import {call, all, put, takeLatest, select} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {GET_FLOOR, GET_TABLES} from './constants';
import {getFloorsSuccess, getFloorsError, getTablesSuccess, getTablesError} from './actions';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';

export function* getFloorDetails() {
  const {branch} = yield select(makeSelectHotel());

  const token = yield select(makeSelectToken());

  const branchId = branch?.id;

  const requestUrl = `${apiUrl}/pos/floors?branch_id=${branchId}`;
  try {
    const response = yield call(apiCall, requestUrl, AuthorizedGetHeaders(token));
    yield put(getFloorsSuccess(response));
  } catch (e) {
    yield put(getFloorsError(e.message));
  }
}

export function* getFloorsSaga() {
  yield takeLatest(GET_FLOOR, getFloorDetails);
}

export function* getTableDetails({floorId}) {
  const token = yield select(makeSelectToken());

  const requestUrl = `${apiUrl}/pos/tables?floor_id=${floorId}`;
  try {
    const response = yield call(apiCall, requestUrl, AuthorizedGetHeaders(token));
    yield put(getTablesSuccess(response));
  } catch (e) {
    yield put(getTablesError(e.message));
  }
}

export function* getTablesSaga() {
  yield takeLatest(GET_TABLES, getTableDetails);
}

export default function* dineInSaga() {
  yield all([getFloorsSaga(), getTablesSaga()]);
}
