import {fromJS} from 'immutable';

import {
  ADD_PRINTER,
  UPDATE_PRINTER,
  REMOVE_PRINTER,
  UPDATE_DEFAULT_PRINTER,
  GET_PRINTERS_SUCCESS,
  GET_PRINTERS,
  GET_PRINTERS_ERROR,
  ADD_PINTER_SUCCESS,
  ADD_PINTER_ERROR,
  REMOVE_PRINTER_SUCCESS,
  REMOVE_PRINTER_ERROR,
  UPDATE_PRINTER_SUCCESS,
  UPDATE_PRINTER_ERROR,
} from './constants';

const initialState = fromJS({
  printers: [],
  default: {
    type: 'usb',
    host: '',
    port: '',
    name: 'zebra',
    title: true,
    checkout: true,
    notes: true,
    paid: true,
    customer: true,
    thankYou: true,
    store: true,
    vat: true,
    prints: 1,
    summary: true,
    categories: [],
  },
});

export default function managementReducer(state = initialState, action) {
  switch (action.type) {

    case ADD_PRINTER:
      return state.set('state', 'loading').set('status', '');
    case ADD_PINTER_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case ADD_PINTER_SUCCESS: {
      if (action.isDefault) {
        return state
          .set('state', 'success')
          .set('default', fromJS(action.printer));
      }
      return state
        .set('state', 'success')
        .update('printers', (printers) =>
          printers.push(fromJS(action.printer)),
        );
    }
    case UPDATE_PRINTER:
      return state.set('state', 'loading').set('status', '');
    case UPDATE_PRINTER_SUCCESS: {
      if (action.isDefault) {
        return state
          .set('state', 'success')
          .set('default', fromJS(action.printer));
      }
      return state
        .set('state', 'success')
        .setIn(['printers', action.index], fromJS(action.printer));
    }
    case UPDATE_PRINTER_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case REMOVE_PRINTER:
      return state.set('state', 'loading').set('status', '');
    case REMOVE_PRINTER_SUCCESS:
      return state.set('state', 'success').deleteIn(['printers', action.index]);
    case REMOVE_PRINTER_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case GET_PRINTERS:
      return state.set('state', 'loading').set('status', '');
    case GET_PRINTERS_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case GET_PRINTERS_SUCCESS:
      return state
        .set('state', 'success')
        .set('default', fromJS(action.defaultPrinter))
        .set('printers', fromJS(action.printers));
    default:
      return state;
  }
}
