import {fromJS, List} from 'immutable';

import {
  ADD_BOOKING,
  SYNC_BOOKING_SUCCESS,
  SYNC_BOOKING_ERROR,
  SYNC_BOOKING,
  CREATE_BOOKING,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_ERROR,
  TOGGLE_ONLINE_STATUS,
  DELETE_LOCAL_BOOKING,
  DELETE_SYNCED_BOOKING,
  UPDATE_BOOKING_STATUS,
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_ERROR,
  FETCH_BOOKINGS_SUCCESS,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR,
  SET_BOOKING_ID,
} from './constants';
import {CLEAR_CART} from '../../Components/Cart/constants';
import {CLOCKOUT} from '../Authentication/constants';

const initialState = fromJS({
  state: 'initial',
  bookings: [],
  status: '',
  online: false,
  bookingId: '',
});

export default function bookingsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOOKINGS:
      return state.set('state', 'loading').set('status', '');
    case FETCH_BOOKINGS_SUCCESS:
      return state.set('state', 'success').set('bookings', fromJS(action.bookings));
    case FETCH_BOOKINGS_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case ADD_BOOKING:
      return state.update('bookings', (bookings) => bookings.unshift(fromJS(action.booking)));

    case CREATE_BOOKING:
      // return state.setIn(['bookings'], fromJS(action.booking)).set('state', 'created');
      return state.set('state', 'created');

    case CREATE_BOOKING_SUCCESS: {
      // NEW START
      let bookingId = action.booking?.booking?.id;

      const getBookings = state.get('bookings')?.toJS();

      const bookingList = List(getBookings);
      if (bookingList?.toJS()?.length > 0) {
        let findItemIndex = bookingList.findIndex((product) => product?.booking?.id === bookingId);

        if (findItemIndex == -1) {
          let updateBooking = bookingList.push(action.booking);
          return state.set('bookings', fromJS(updateBooking)).set('bookingId', fromJS(bookingId));
        } else {
          return state
            .setIn(
              ['bookings', findItemIndex],
              fromJS({
                ...action.booking,
                syncState: 'synced',
              }),
            )
            .set('bookingId', fromJS(bookingId));
        }
      } else {
        let updateBooking = bookingList.push(action.booking);
        return state.set('bookings', fromJS(updateBooking)).set('bookingId', fromJS(bookingId));
      }
      // NEW END
    }

    case CREATE_BOOKING_ERROR: {
      const index = state.get('bookings').findIndex((booking) => booking.bookingUnique === action.bookingUnique);
      if (index >= 0) {
        return state.setIn(['bookings', index, 'syncState'], 'local').set('status', action.status);
      }
      return state;
    }
    case SYNC_BOOKING:
      return state.set('state', 'loading').set('status', '');
    case SYNC_BOOKING_SUCCESS: {
      const index = state.get('bookings').findIndex((booking) => booking.get('bookingUnique') === action.bookingUnique);
      if (index >= 0) {
        return state
          .setIn(
            ['bookings', index],
            fromJS({
              ...action.booking,
              syncState: 'synced',
              bookingUnique: action.bookingUnique,
            }),
          )
          .set('state', 'success');
      }
      return state.set('state', 'success');
    }
    case SYNC_BOOKING_ERROR:
      return state.set('state', 'error').set('status', '');
    case DELETE_SYNCED_BOOKING: {
      const index = state.get('bookings').findIndex((booking) => booking.getIn(['booking', 'id']) === action.bookingId);
      return state.deleteIn(['bookings', index]);
    }
    case UPDATE_BOOKING_STATUS: {
      const index = state.get('bookings').findIndex((booking) => booking.get('bookingUnique') === action.unique);
      return state
        .updateIn(['bookings', index, 'payment'], (payments) =>
          payments.map((payment) => payment.set('transaction_status', action.status)),
        )
        .setIn(['bookings', index, 'booking', 'status'], action.status);
    }
    case DELETE_LOCAL_BOOKING: {
      const index = state.get('bookings').findIndex((booking) => booking.get('bookingUnique') === action.unique);
      return state.deleteIn(['bookings', index]);
    }
    case UPDATE_BOOKING: {
      // const index = state
      //   .get('bookings')
      //   .findIndex((booking) => booking.getIn(['booking', 'id']) === action.booking.booking_id);
      // if (index >= 0) {
      //   return state.setIn(['bookings', index], fromJS(action.booking));
      // }
      return state.set('state', 'success');
    }
    case UPDATE_BOOKING_SUCCESS: {
      // const index = state.get('bookings').findIndex((booking) => booking.get('bookingUnique') === action.bookingUnique);
      // if (index >= 0) {
      //   return state
      //     .setIn(
      //       ['bookings', index],
      //       fromJS({
      //         ...action.booking,
      //         syncState: 'synced',
      //         bookingUnique: action.bookingUnique,
      //       }),
      //     )
      //     .set('state', 'success');
      // }
      return state.set('state', 'success');
    }
    case UPDATE_BOOKING_ERROR: {
      const index = state.get('bookings').findIndex((booking) => booking.bookingUnique === action.bookingUnique);
      if (index >= 0) {
        return state.setIn(['bookings', index, 'syncState'], 'local').set('status', action.status);
      }
      return state;
    }
    case SET_BOOKING_ID:
      return state.set('bookingId', fromJS(action.bookingId));
    case TOGGLE_ONLINE_STATUS:
      return state.set('online', action.status);
    case CLEAR_CART:
      return state.set('state', 'initial');
    case CLOCKOUT:
      return initialState;
    default:
      return state;
  }
}
