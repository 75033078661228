export const SPLIT_DINEIN_ORDER = 'Favorite/Dinein/SPLIT_DINEIN_ORDER';

export const SET_DINEIN_REMAINING = 'Favorite/Dinein/SET_DINEIN_REMAINING';

export const SET_DINEIN_CHANGE = 'Favorite/Dinein/SET_DINEIN_CHANGE';

export const SET_DINEIN_DISPLAY_VALUE = 'Favorite/Dinein/SET_DINEIN_DISPLAY_VALUE';

export const SET_DINEIN_PAYMENT = 'Favorite/Dinein/SET_DINEIN_PAYMENT';

export const REMOVE_DINEIN_PAYMENT = 'Favorite/Dinein/REMOVE_DINEIN_PAYMENT';

export const CLEAR_DINEIN_PAYMENT = 'Favorite/Dinein/CLEAR_DINEIN_PAYMENT';

export const SET_DINEIN_PAYMENTS = 'Favorite/Dinein/SET_DINEIN_PAYMENTS';

export const SET_DINEIN_SPLITING = 'Favorite/Dinein/SET_DINEIN_SPLITING';
