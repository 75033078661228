export const REMOVE_PRINTER = 'Favorite/Management/REMOVE_PRINTER';

export const REMOVE_PRINTER_SUCCESS =
  'Favorite/Management/REMOVE_PRINTER_SUCCESS';

export const REMOVE_PRINTER_ERROR = 'Favorite/Management/REMOVE_PRINTER_ERROR';

export const UPDATE_PRINTER = 'Favorite/Management/UPDATE_PRINTER';

export const UPDATE_PRINTER_SUCCESS =
  'Favorite/Management/UPDATE_PRINTER_SUCCESS';

export const UPDATE_PRINTER_ERROR = 'Favorite/Management/UPDATE_PRINTER_ERROR';

export const ADD_PRINTER = 'Favorite/Management/ADD_PRINTER';

export const ADD_PINTER_ERROR = 'Favorite/Management/ADD_PINTER_ERROR';

export const ADD_PINTER_SUCCESS = 'Favorite/Management/ADD_PINTER_SUCCESS';

export const GET_PRINTERS = 'Favorite/Management/GET_PRINTERS';

export const GET_PRINTERS_SUCCESS = 'Favorite/Management/GET_PRINTERS_SUCCESS';

export const GET_PRINTERS_ERROR = 'Favorite/Management/GET_PRINTERS_ERROR';