/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux-immutable';
import {connectRouter} from 'connected-react-router/immutable';
import {withReduxStateSync} from 'redux-state-sync';

import {fromJS} from 'immutable';
import Menu from '../Reducers/Menu';
import authReducer from '../Pages/Authentication/reducer';
import hydrateReducer from './RehydrateReducer';
import bookingsReducer from '../Pages/Bookings/reducer';
import cartReducer from '../Components/Cart/reducer';
import customerReducer from '../Pages/Customer/reducer';
import notesReducer from '../Pages/Notes/reducer';
import checkoutReducer from '../Pages/Checkout/reducer';
import dineInCheckoutReducer from '../Pages/DineInCheckout/reducer';
import driversReducer from '../Pages/Drivers/reducer';
import tasksReducer from '../Pages/Tasks/reducer';
import tillReducer from '../Pages/TillManagement/reducer';
import managementReducer from '../Pages/Management/reducer';
import dineInReducer from '../Pages/DineIn/reducer';
import PendingOrders from '../Components/PendingOrders/reducer';

export default function createReducer(history) {
  return withReduxStateSync(
    combineReducers({
      router: connectRouter(history),
      hydrate: hydrateReducer,
      auth: authReducer,
      menu: Menu,
      cart: cartReducer,
      bookings: bookingsReducer,
      customer: customerReducer,
      notes: notesReducer,
      checkout: checkoutReducer,
      dineInCheckout: dineInCheckoutReducer,
      drivers: driversReducer,
      tasks: tasksReducer,
      till: tillReducer,
      management: managementReducer,
      dineIn: dineInReducer,
      tableCart: PendingOrders,
    }),
    (state) => fromJS(state),
  );
}
