import React, {useEffect, useState} from 'react';
import {Button, Alert} from 'reactstrap';
import {useHistory, Redirect} from 'react-router-dom';
import Form from 'reactstrap/es/Form';
import Row from 'reactstrap/es/Row';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import FormGroup from 'reactstrap/es/FormGroup';
import Label from 'reactstrap/es/Label';
import Input from 'reactstrap/es/Input';
import {clearCart, selectItem} from '../../Components/Cart/actions';
import Master from '../Master';
import Payments from './Payments';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import printOfflineBooking from '../../Libs/printOfflineBooking';
import {makeSelectDefaultPrinter} from '../Management/selectors';
import {makeSelectProducts} from '../../Components/PendingOrders/selectors';
import {checkIfObjectEmpty} from '../../Libs/object';
import {makeSelectDineInChange} from './selectors';
import openTill from '../../Libs/openTill';
import Price from '../../Components/Price';
import {makeSelectBookings} from '../Bookings/selectors';
import {makeSelectTableId} from '../DineIn/selectors';
import {filterItemsByTableId} from '../../Libs/filterItemsByTableId';
import MasterDineIn from '../MasterDineIn';

function DineInCheckout() {
  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());

  const items = useSelector(makeSelectProducts());
  const tableId = useSelector(makeSelectTableId());
  const booking = useSelector(makeSelectBookings());
  const change = useSelector(makeSelectDineInChange());

  let itemObj = filterItemsByTableId(items, tableId);
  let products = itemObj?.products;

  const notes = itemObj?.notes;
  const customer = itemObj?.customer;
  const {push} = useHistory();
  const [state, setState] = useState('initial');
  const [receipt, setReceipt] = useState(true);

  useEffect(() => {
    dispatch(selectItem(-1));
  });

  const done = async () => {
    dispatch(clearCart());

    if (receipt) {
      const customerData = checkIfObjectEmpty(customer) ? branch.walk_in_user?.walk_in_user_address : customer;
      let receiptNotes = notes.length ? notes.join(', ') : '';
      await printOfflineBooking(customerData, receiptNotes, change, booking, [], branch, true, false);
    } else {
      await openTill(defaultPrinter);
    }
    push('/');
  };

  if (token.length === 0) {
    return <Redirect to="/login" />;
  }

  if (state === 'success') {
    return (
      <MasterDineIn>
        <div className="container-fluid customer-container">
          <Row className="response">
            <Alert color="success">Successfully Created booking</Alert>
            {change > 0 && (
              <Row style={{width: '320px', margin: '15px'}}>
                <h5 className="change">
                  Change:{' '}
                  <strong className="price">
                    <Price>{change}</Price>
                  </strong>
                </h5>
              </Row>
            )}
            <FormGroup check>
              <Label check>
                <Input type="checkbox" defaultChecked={receipt} onChange={() => setReceipt(!receipt)} /> Print Receipt
              </Label>
            </FormGroup>
          </Row>
          <Row className="justify-content-center">
            <Button className="w-25 p-4 btn btn-secondary" color="secondary" onClick={done}>
              Done
            </Button>
          </Row>
        </div>
      </MasterDineIn>
    );
  }

  return (
    <MasterDineIn>
      <div className="container-fluid customer-container">
        <Form onSubmit={(e) => e.preventDefault()} className="checkout-form">
          <Payments setState={setState} state={state} itemObj={itemObj} products={products} />
        </Form>
      </div>
    </MasterDineIn>
  );
}

export default DineInCheckout;
