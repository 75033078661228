import React, {useState} from 'react';
import {Spinner} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
  makeSelectMenu,
  makeSelectMenuChildrens,
  makeSelectSelectedMenu,
  makeSelectSelectedSubMenu,
} from '../../Selectors/Menu';
import {setMenuChildrens, setSingleMenu, setSingleSubMenu} from '../../Actions/Menu';
import history from '../../Libs/history';
import {makeSelectCheckoutMode} from '../Cart/selectors';

export default function TopNav() {
  const dispatch = useDispatch();
  const menu = useSelector(makeSelectMenu());
  const menuChildrens = useSelector(makeSelectMenuChildrens());
  const mode = useSelector(makeSelectCheckoutMode());
  const selectedMenu = useSelector(makeSelectSelectedMenu());

  const selectedSubMenu = useSelector(makeSelectSelectedSubMenu());

  const [childrenMenu, setChildrenMenu] = useState([]);

  const setMenuHandler = (submenu) => {
    if ('childrens' in submenu) {
      setChildrenMenu(submenu?.childrens);
      // dispatch(setMenuChildrens(submenu?.childrens));
    }
    dispatch(setSingleMenu(submenu));
    history.push('/dashboard');
  };

  const setSubMenuHandler = (submenu) => {
    dispatch(setSingleSubMenu(submenu));
    history.push('/dashboard');
  };

  let updatedMode = null;

  if (mode == 'Delivery') {
    updatedMode = 'Delivery';
  } else if (mode == 'Takeaway') {
    updatedMode = 'Takeaway';
  } else if (mode == 'Dine-in') {
    updatedMode = 'Dinein';
  }

  const renderMenus = () => {
    if (mode === 'Dine-in') {
      return menu.map((submenu) => {
        let classActive = '';
        if (submenu.id === selectedMenu.id) {
          classActive = 'active-menu';
        } else {
          classActive = '';
        }
        if (submenu.visible_in == 'dinein') {
          return (
            <div key={submenu.id} className={`nav-custom ${classActive}`} onClick={() => setMenuHandler(submenu)}>
              {submenu.title}
            </div>
          );
        }
      });
    } else {
      return menu.map((submenu) => {
        let classActive = '';
        if (submenu.id === selectedMenu.id) {
          classActive = 'active-menu';
        } else {
          classActive = '';
        }
        if (submenu.visible_in === updatedMode.toLowerCase() || submenu.visible_in === 'both') {
          return (
            <div key={submenu.id} className={`nav-custom ${classActive}`} onClick={() => setMenuHandler(submenu)}>
              {submenu.title}
            </div>
          );
        }
      });
    }
  };

  const renderSubMenus = () => {
    return childrenMenu.map((submenu) => {
      let classActive = '';
      if (submenu.id === selectedSubMenu.id) {
        classActive = 'active-menu';
      } else {
        classActive = '';
      }
      return (
        <div
          key={submenu.id}
          style={{backgroundColor: classActive ? '#e5e5e5' : 'blue'}}
          className={`nav-custom ${classActive}`}
          onClick={() => setSubMenuHandler(submenu)}
        >
          {submenu.title}
        </div>
      );
    });
  };

  return (
    <div className="topNav">
      <div className="nav-scroll">
        {menu.length > 0 ? (
          renderMenus()
        ) : (
          <div className="nav-custom">
            <Spinner color="light" />
          </div>
        )}
      </div>

      <div className="nav-scroll" style={{marginTop: 20, marginLeft: 50, marginBottom: 20, alignItems: 'center'}}>
        {childrenMenu?.length ? 'CHILDREN MENU :' : null}
        {childrenMenu.length > 0 ? renderSubMenus() : null}
      </div>
    </div>
  );
}
