import React, {useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import qz from 'qz-tray';
import Price from '../../Components/Price';
import {paidStatus} from '../../Libs/bookings';
import Loadable from '../../Components/Loadable';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import Drivers from './Drivers';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {fetchTasks} from './actions';
import {getTaskReceipt} from '../../Libs/printTask';
import {makeSelectDefaultPrinter} from '../Management/selectors';
import QzTray from '../../Configs/QZTray';

function Task({task, setStatus}) {
  const dispatch = useDispatch();
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const [state, setState] = useState('initial');
  const [mode, setMode] = useState('initial');
  const [toggleStatus, setToggleStatus] = useState(false);
  const [assign, setAssign] = useState(false);
  const handleAssign = (driver) => {
    setState('loading');
    setMode('assign');
    setAssign(false);
    setStatus('');
    apiCall(
      `${apiUrl}/tasks/assignDriver`,
      AuthorizedPostHeaders(
        {
          task_id: task.id,
          driver_id: driver.id,
        },
        token,
      ),
    )
      .then(() => {
        setState('success');
        // console.log('response', response);
        dispatch(fetchTasks());
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        console.log('e', e);
      });
  };

  const handleUnAssign = () => {
    setState('loading');
    setMode('assign');
    setAssign(false);
    setStatus('');
    apiCall(`${apiUrl}/tasks/unAssignDriver/${task.id}`, AuthorizedGetHeaders(token))
      .then(() => {
        setState('success');
        // console.log('response', response);
        dispatch(fetchTasks());
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        // console.log('e', e);
      });
  };

  const handleUpdateStatus = (status) => {
    setState('loading');
    setMode('status');
    setToggleStatus(false);
    setStatus('');
    apiCall(
      `${apiUrl}/tasks/updateTask`,
      AuthorizedPostHeaders(
        {
          branch_id: branch.id,
          task_id: task.id,
          status,
        },
        token,
      ),
    )
      .then((response) => {
        setState('success');
        dispatch(fetchTasks());
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        console.log('e', e);
      });
  };

  const handlePrintTask = async () => {
    try {
      const printString = getTaskReceipt(task, branch, defaultPrinter);
      const config = await QzTray(defaultPrinter);
      await qz.print(config, printString);
      await qz.websocket.disconnect();
    } catch (e) {
      console.log('offline print error', e);
    }
  };

  if (assign) {
    return <Drivers handleAssign={handleAssign} handleCancel={() => setAssign(false)} />;
  }
  if (toggleStatus) {
    return (
      <div className="job-actions">
        <i onClick={() => setToggleStatus(false)} className="cancel-action fa fa-times" />
        <button className="cancel" type="button" onClick={() => handleUpdateStatus('cancelled')}>
          Cancel
        </button>
        <button className="complete" type="button" onClick={() => handleUpdateStatus('completed')}>
          Complete
        </button>
        <button className="refund" type="button" onClick={() => handleUpdateStatus('refunded')}>
          Refund
        </button>
        <button className="incomplete" type="button" onClick={() => handleUpdateStatus('incomplete')}>
          In Complete
        </button>
        <Link className="edit" to={`/tasks/${task.id}`}>
          Edit
        </Link>
      </div>
    );
  }
  return (
    <li key={task.id}>
      <div className="booking">
        <div className="booking-info">
          <button type="button" className="platform" onClick={handlePrintTask}>
            <span className="platform-button bg-info">{task.vendor.title}</span>
          </button>
          <div className="address-details">
            <div className="address">{task.address}</div>
            <div className="postcode">{task.postcode}</div>
            <div className="order-id">{task.reference_no}</div>
          </div>
        </div>
        <div className="payment-info">
          <div className="amount">
            <Price>{task.total_charges}</Price>
          </div>
          <div className="status">{paidStatus([{mode: task.payment_method}])}</div>
        </div>
        <div className="time-info">
          <p className="status">
            <strong className={task.checkout_mode}>
              {task.checkout_mode === 'takeaway' ? 'Collection' : 'Delivery'}
            </strong>
          </p>
          <p className="value">{moment(task.created_at).format('HH:mm')}</p>
        </div>
        <div className="payment-method">
          {task.driver_id !== null && (
            <div className="assigned-driver">
              {task.driver?.user_address?.name || ''} ({moment(task.updated_at).format('HH:mm')})
            </div>
          )}
          <div className="assign-buttons">
            {/* eslint-disable-next-line no-nested-ternary */}
            {task.status === 'incomplete' ? (
              task.driver_id === null ? (
                <button className="assign" type="button" onClick={() => setAssign(true)}>
                  <Loadable color="white" active={state === 'loading' && mode === 'assign'}>
                    ASSIGN
                  </Loadable>
                </button>
              ) : (
                <button className="assigned" type="button" onClick={handleUnAssign}>
                  <Loadable color="white" active={state === 'loading' && mode === 'assign'}>
                    UNASSIGN
                  </Loadable>
                </button>
              )
            ) : (
              <button className="complete" type="button">
                {task.status}
              </button>
            )}
            <button className="update" type="button" onClick={() => setToggleStatus(true)}>
              <Loadable color="white" active={state === 'loading' && mode === 'status'}>
                UPDATE
              </Loadable>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Task;
