import {
  SET_DINEIN_CHANGE,
  SPLIT_DINEIN_ORDER,
  SET_DINEIN_PAYMENT,
  SET_DINEIN_DISPLAY_VALUE,
  REMOVE_DINEIN_PAYMENT,
  SET_DINEIN_PAYMENTS,
  SET_DINEIN_SPLITING,
  CLEAR_DINEIN_PAYMENT,
} from './constants';

export const setChange = (change) => ({
  type: SET_DINEIN_CHANGE,
  change,
});

export const setPayment = (payment, change) => ({
  type: SET_DINEIN_PAYMENT,
  payment,
  change,
});

export const setPayments = (payments, change) => ({
  type: SET_DINEIN_PAYMENTS,
  payments,
  change,
});

export const removePayment = (index) => ({
  type: REMOVE_DINEIN_PAYMENT,
  index,
});

export const clearDineInPayment = () => ({
  type: CLEAR_DINEIN_PAYMENT,
});

export const setDisplayValue = (value) => ({
  type: SET_DINEIN_DISPLAY_VALUE,
  value,
});

export const splitOrder = (status) => ({
  type: SPLIT_DINEIN_ORDER,
  status,
});

export const setSpliting = (status) => ({
  type: SET_DINEIN_SPLITING,
  status,
});
