import {
  ADD_TO_TABLE_CART,
  INCREASE_TABLE_CART_QUANTITY,
  DECREASE_TABLE_CART_QUANTITY,
  REMOVE_TABLE_CART_ITEM,
  ADD_TABLE_CUSTOMER,
  TOGGLE_TABLE_CHECKOUT,
  ADD_TABLE_DISCOUNT,
  REMOVE_TABLE_DISCOUNT,
  ADD_TABLE_PAYMENT,
  REMOVE_TABLE_PAYMENT,
  CLEAR_TABLE_CART,
  ADD_TABLE_NOTES,
  REMOVE_TABLE_NOTES,
  SELECT_TABLE_ITEM,
  ADD_TABLE_EXTRAS,
  ADD_HOLD_TABLE_ITEMS,
  UPDATE_TABLE_CART_ITEM,
  SAVE_TABLE_OPERATION,
  ADD_TABLE_CART,
  SET_TABLE_CART_BOOKING_ID,
  UPDATE_AND_PRINT_TABLE_CART,
  COMPLETE_DINEIN_BOOKING,
  REMOVE_ITEM_BY_BOOKING_ID,
} from './constants';

export const addTocart = (items, tableId, floorId) => {
  return {
    type: ADD_TO_TABLE_CART,
    items,
    tableId,
    floorId,
  };
};

export const setTableCartBookingId = (tableId, bookingId) => {
  return {
    type: SET_TABLE_CART_BOOKING_ID,
    tableId,
    bookingId,
  };
};

export const updateCartItem = (item, parentIndex, index) => ({
  type: UPDATE_TABLE_CART_ITEM,
  item,
  parentIndex,
  index,
});

export const increaseQuantity = (index, quantity) => {
  return {
    type: INCREASE_TABLE_CART_QUANTITY,
    index,
    quantity,
  };
};

export const decreaseQuantity = (index, quantity) => {
  return {
    type: DECREASE_TABLE_CART_QUANTITY,
    index,
    quantity,
  };
};

export const removeItem = (parentIndex, index) => {
  return {
    type: REMOVE_TABLE_CART_ITEM,
    index,
    parentIndex,
  };
};

export const removeItemByBookingId = (bookingId) => {
  return {
    type: REMOVE_ITEM_BY_BOOKING_ID,
    bookingId,
  };
};

export const toggleCheckout = (mode) => ({
  type: TOGGLE_TABLE_CHECKOUT,
  mode,
});

export const addCustomer = (customer) => ({
  type: ADD_TABLE_CUSTOMER,
  customer,
});

export const addDiscount = (bookingId, discount) => ({
  type: ADD_TABLE_DISCOUNT,
  bookingId,
  discount,
});

export const removeDiscount = () => ({
  type: REMOVE_TABLE_DISCOUNT,
});

export const addPayment = (payment) => ({
  type: ADD_TABLE_PAYMENT,
  payment,
});

export const removePayment = () => ({
  type: REMOVE_TABLE_PAYMENT,
});

export const addNotes = (notes) => ({
  type: ADD_TABLE_NOTES,
  notes,
});

export const removeNotes = () => ({
  type: REMOVE_TABLE_NOTES,
});

export const clearCart = () => ({
  type: CLEAR_TABLE_CART,
});

export const selectItem = (index, product) => ({
  type: SELECT_TABLE_ITEM,
  product,
  index,
});

export const addHoldItems = (items, index) => ({
  type: ADD_HOLD_TABLE_ITEMS,
  items,
  index,
});

export const addExtras = (items, index) => ({
  type: ADD_TABLE_EXTRAS,
  items,
  index,
});

export const saveOperation = (operation) => ({
  type: SAVE_TABLE_OPERATION,
  operation,
});

export const addCart = (cart, tableId, floorId) => ({
  type: ADD_TABLE_CART,
  cart,
  tableId,
  floorId,
});

export const updateAndPrintTableCart = () => ({
  type: UPDATE_AND_PRINT_TABLE_CART,
});

export const completeDineInBooking = (bookingObj) => {
  return {
    type: COMPLETE_DINEIN_BOOKING,
    bookingObj,
  };
};
