import {fromJS, List} from 'immutable';
import {
  ADD_TO_TABLE_CART,
  INCREASE_TABLE_CART_QUANTITY,
  DECREASE_TABLE_CART_QUANTITY,
  REMOVE_TABLE_CART_ITEM,
  ADD_TABLE_CUSTOMER,
  TOGGLE_TABLE_CHECKOUT,
  ADD_TABLE_DISCOUNT,
  REMOVE_TABLE_DISCOUNT,
  ADD_TABLE_PAYMENT,
  REMOVE_TABLE_PAYMENT,
  CLEAR_TABLE_CART,
  ADD_TABLE_NOTES,
  REMOVE_TABLE_NOTES,
  SELECT_TABLE_ITEM,
  ADD_TABLE_EXTRAS,
  ADD_HOLD_TABLE_ITEMS,
  UPDATE_TABLE_CART_ITEM,
  SAVE_TABLE_OPERATION,
  ADD_TABLE_CART,
  SET_TABLE_CART_BOOKING_ID,
  UPDATE_AND_PRINT_TABLE_CART,
  REMOVE_ITEM_BY_BOOKING_ID,
} from './constants';
import {ADD_NOTE, REMOVE_NOTE} from '../../Pages/Notes/constants';
import {EDIT_BOOKING} from '../../Pages/Bookings/constants';
import {FIND_CUSTOMER_SUCCESS} from '../../Pages/Customer/constants';
import {checkIfObjectEmpty} from '../../Libs/object';

const initialState = fromJS({
  loading: false,
  success: false,
  error: false,
  status: '',
  items: [],
  recentPrintItemIds: [],
  products: [],
  checkoutMode: 'Dine-in',
  customer: {},
  discount: {},
  payment: {},
  floorId: '',
  tableId: '',
  notes: [],
  selectedIndex: -1,
  selectedProduct: {},
  operation: {},
  bookingMode: 'generate',
  bookingId: '',
});

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_TABLE_CART:
      return state.update('products', (products) => products.unshift(action.item));
    case ADD_TABLE_CART:
      let cartItem = {
        products: action.cart,
        checkoutMode: 'Dine-in',
        customer: {},
        discount: {},
        payment: {},
        floorId: action.floorId,
        tableId: action.tableId,
        notes: [],
        selectedIndex: -1,
        selectedProduct: {},
        operation: {},
        bookingMode: 'generate',
        bookingId: '',
      };
      const getProducts = state.get('items')?.toJS();

      const productList = List(getProducts);

      let findItemIndex = productList.findIndex((product) => product.tableId === action.tableId);

      if (findItemIndex == -1) {
        let updateProducts = productList.push(fromJS(cartItem));
        return state.set('items', fromJS(updateProducts)).set('recentPrintItemIds', fromJS(action.cart));
      } else {
        let updateProduct = productList.toJS()[findItemIndex];

        const listOfProducts = List(updateProduct?.products);

        let addItemToProductsArray = listOfProducts.concat(fromJS(action.cart));

        return state
          .setIn(['items', findItemIndex, 'products'], fromJS(addItemToProductsArray))
          .set('recentPrintItemIds', fromJS(action.cart));
      }

    // TABLE CART BOOKING ID
    case SET_TABLE_CART_BOOKING_ID:
      const getTableProducts = state.get('items')?.toJS();

      const tableProductList = List(getTableProducts)?.toJS();

      let findTableItemIndex = tableProductList.findIndex((product) => product.tableId === action.tableId);

      if (findTableItemIndex == -1) {
      } else {
        return state.setIn(['items', findTableItemIndex, 'bookingId'], fromJS(action.bookingId));
      }
    // END TABLE CART BOOKING ID

    case UPDATE_TABLE_CART_ITEM:
      const updateGetProducts = state.get('items')?.toJS();

      const updateProductList = List(updateGetProducts);

      let updateProduct = updateProductList.toJS()[action.parentIndex];

      const singleUpdateProductList = List(updateProduct?.products)?.toJS();

      singleUpdateProductList[action.index] = action.item?.toJS();

      return state.setIn(['items', action.parentIndex, 'products'], fromJS(singleUpdateProductList));

    case UPDATE_AND_PRINT_TABLE_CART:
      return state.set('recentPrintItemIds', fromJS([]));

    case INCREASE_TABLE_CART_QUANTITY:
      return state.setIn(['products', action.index, 'quantity'], action.quantity);
    case DECREASE_TABLE_CART_QUANTITY:
      return state.setIn(['products', action.index, 'quantity'], action.quantity);
    case REMOVE_TABLE_CART_ITEM:
      const listItems = state.get('items')?.toJS();

      const mutateItems = List(listItems)?.toJS();

      let product = mutateItems[action?.parentIndex]?.products[action?.index];

      if (!checkIfObjectEmpty(product)) {
        let filterItems = mutateItems[action?.parentIndex]?.products?.filter((row, index) => {
          return index !== action?.index;
        });

        return state.setIn(['items', action.parentIndex, 'products'], fromJS(filterItems));
      }

      return state.set('selectedIndex', -1);

    case REMOVE_ITEM_BY_BOOKING_ID:
      const listCartItems = state.get('items')?.toJS();

      const mutateCartItems = List(listCartItems)?.toJS();

      let filterCartItems = mutateCartItems?.filter((row) => {
        return row?.bookingId !== action?.bookingId;
      });

      return state.set('items', fromJS(filterCartItems)).set('recentPrintItemIds', fromJS([]));

    case ADD_TABLE_CUSTOMER:
      return state.set('customer', fromJS(action.customer));
    case TOGGLE_TABLE_CHECKOUT:
      return state.set('checkoutMode', action.mode);
    case ADD_TABLE_DISCOUNT:
      const listItemObj = state.get('items')?.toJS();

      const mutateCartItemObj = List(listItemObj)?.toJS();
      let findItemObjIndex = mutateCartItemObj.findIndex((product) => product.bookingId == action.bookingId);

      if (findItemObjIndex != -1) {
        return state.setIn(['items', findItemObjIndex, 'discount'], fromJS(action.discount));
      }

      return state.set('selectedIndex', -1);

    case REMOVE_TABLE_DISCOUNT:
      return state.set('discount', fromJS({}));
    case ADD_TABLE_PAYMENT:
      return state.set('payment', fromJS(action.payment));
    case REMOVE_TABLE_PAYMENT:
      return state.set('payment', fromJS({}));
    case ADD_TABLE_NOTES:
      return state.set('notes', action.notes);
    case REMOVE_TABLE_NOTES:
      return state.set('notes', '');
    case SELECT_TABLE_ITEM: {
      if (action.index === -1) {
        return state.set('selectedIndex', -1).set('selectedProduct', fromJS({}));
      }
      if (state.get('selectedIndex') === action.index) {
        return state.set('selectedIndex', -1).set('selectedProduct', fromJS({}));
      }
      return state.set('selectedIndex', action.index).set('selectedProduct', fromJS(action.product));
    }
    case ADD_HOLD_TABLE_ITEMS:
      return state.setIn(['products', action.index, 'hold'], fromJS(action.items));
    case ADD_TABLE_EXTRAS:
      return state.setIn(['products', action.index, 'extras'], fromJS(action.items));
    case ADD_NOTE:
      return state.update('notes', (notes) => notes.push(action.note));
    case REMOVE_NOTE:
      return state.deleteIn(['notes', action.index]);
    case SAVE_TABLE_OPERATION:
      return state.set('operation', fromJS(action.operation));
    case EDIT_BOOKING:
      return state
        .set('products', fromJS(action.products))
        .set('bookingMode', 'edit')
        .set('discount', fromJS(action.discount))
        .set('customer', fromJS(action.customer))
        .set('checkoutMode', action.checkoutMode)
        .set('notes', fromJS(action.notes))
        .set('bookingId', action.bookingId);
    case FIND_CUSTOMER_SUCCESS: {
      if (action.products.length > 0) {
        return state.set('products', fromJS(action.products));
      }
      return state;
    }
    case CLEAR_TABLE_CART:
      return initialState;
    default:
      return state;
  }
}
