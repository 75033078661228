import React, {useEffect, useState} from 'react';
import Row from 'reactstrap/es/Row';
import {Button, Col} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, Redirect} from 'react-router-dom';

import {checkIfObjectEmpty, reformatTelephoneNumber} from '../../Libs/object';
import {makeSelectBookingMode, makeSelectSelectedNotes} from '../../Components/Cart/selectors';
import {makeSelectHotel, makeSelectUser, makeSelectWalkInUser} from '../Authentication/selectors';
import {
  calculateRemaining,
  cartTotal,
  getDeliveryCharges,
  getDiscountPrice,
  getMinOrderAmount,
  toCents,
} from '../../Libs/prices';
import Numpad from './Numpad';
import Price from '../../Components/Price';
import CardPayment from './CardPayment';
import Print from '../../Components/Print';
import {
  makeSelectDineInChange,
  makeSelectDineInDisplayValue,
  makeSelectDineInPayments,
  makeSelectDineInSplitOrder,
} from './selectors';
import {removePayment, setChange, setDisplayValue, setPayment, setSpliting, splitOrder} from './actions';
import CustomerAddress from './CustomerAddress';
import {completeDineInBooking} from '../../Components/PendingOrders/actions';

function Payments({setState, itemObj, products}) {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const walkInUser = useSelector(makeSelectWalkInUser());
  const notes = useSelector(makeSelectSelectedNotes());
  const payments = useSelector(makeSelectDineInPayments());
  const displayValue = useSelector(makeSelectDineInDisplayValue());
  const change = useSelector(makeSelectDineInChange());
  const split = useSelector(makeSelectDineInSplitOrder());
  const {branch} = useSelector(makeSelectHotel());

  const bookingMode = useSelector(makeSelectBookingMode());

  const user = useSelector(makeSelectUser());

  // Variables
  const discount = itemObj?.discount;
  const customer = itemObj?.customer;
  const bookingId = itemObj?.bookingId;
  const operation = itemObj?.operation;
  const floorId = itemObj?.floorId;
  const tableId = itemObj?.tableId;
  const minOrderAmount = getMinOrderAmount(branch, itemObj?.checkoutMode);
  const cartTotalAmount = cartTotal(products, itemObj?.checkoutMode);
  const subTotal = minOrderAmount > cartTotalAmount ? minOrderAmount : cartTotalAmount;
  const discountPrice = getDiscountPrice(discount, subTotal);
  const deliveryCharges = getDeliveryCharges(operation, itemObj?.checkoutMode, subTotal);

  const total = subTotal - discountPrice + deliveryCharges;
  const remaining = calculateRemaining(total, payments);

  const [disabled, setDisabled] = useState(false);

  const handleDeletePayment = (i) => {
    dispatch(removePayment(i));
  };

  useEffect(() => {
    if (toCents(remaining) > 0) {
      dispatch(setChange(0));
    }
  }, [remaining]);

  const handleCheckout = () => {
    setDisabled(true);
    let formData = {};

    formData = checkIfObjectEmpty(customer)
      ? {
          ...walkInUser,
          telephone: reformatTelephoneNumber(walkInUser.telephone),
        }
      : {...customer, telephone: reformatTelephoneNumber(customer.telephone)};

    if (products.length === 0) {
      alert('Cart is empty');
      setDisabled(false);
      return;
    }
    dispatch(
      completeDineInBooking({
        booking_id: bookingId,
        floor_id: floorId,
        table_id: tableId,
        clockin_user_id: user?.id,
        branch_slug: branch.slug,
        checkout_mode: itemObj?.checkoutMode,
        sub_total: subTotal,
        discount_amount: discountPrice,
        delivery_charges: deliveryCharges,
        payments,
        formData: {
          ...formData,
          telephone: reformatTelephoneNumber(formData.telephone),
        },
        notes: notes.length ? notes.join(', ') : '',
        cart: products,
        bookingUnique: uuidv4(),
        syncState: 'syncing',
        checkout_time: moment().format('YY-MM-DD HH:mm'),
      }),
    );
    setDisabled(false);
    setState('success');
    push('/');
  };

  const handleAddPayment = (m, terminal) => {
    let c = 0;
    if (toCents(displayValue) > toCents(remaining)) {
      c = toCents(displayValue) - toCents(remaining);
    }
    dispatch(setPayment({mode: m, amount: toCents(displayValue) - c, terminal}, c));
    if (split) {
      dispatch(setSpliting(true));
    }
  };
  return (
    <Row className="payment-dashboard">
      <Col>
        <Row>
          <h2 className="total-amount text-secondary">
            Total Amount: <Price>{total}</Price>{' '}
          </h2>
        </Row>
        <Row>
          <div className="payment-summary">
            {payments.map((payment, i) => (
              <div className="payment-row" key={`${payment.amount} ${i}`}>
                <div className="left">
                  <Price>{payment.amount}</Price> - {payment.mode}
                </div>
                <div className="right">
                  <i className="fa fa-ban text-danger" onClick={() => handleDeletePayment(i)} />
                </div>
              </div>
            ))}
          </div>
        </Row>
        <Row>
          {/* <Print /> */}
          <Link to={`/dine-in-discounts/${itemObj?.bookingId}`} className="action-button bg-success text-light">
            <i className="fa fa-percent" />
            <span>DISCOUNTS</span>
          </Link>
          <Button className="action-button bg-danger" onClick={() => dispatch(splitOrder(!split))}>
            <i className="fa fa-scissors" />
            <span>DIVIDE TABLE</span>
          </Button>
        </Row>
        <Row>
          <Col className="addresses">
            <CustomerAddress />
          </Col>
        </Row>
      </Col>
      <Col className="ml-2">
        <Row>
          <h2 className="total-amount text-secondary">Pay</h2>
        </Row>
        <Row className="numpad-wrapper">
          <Numpad
            remaining={remaining}
            displayValue={displayValue}
            setDisplayValue={(value) => dispatch(setDisplayValue(value))}
            setSpliting={setSpliting}
          />
        </Row>
        {toCents(remaining) !== 0 && (
          <Row>
            <Button className="action-button bg-ternary" onClick={() => handleAddPayment('cash', '')}>
              <i className="fa fa-money" />
              <span>CASH</span>
            </Button>
            <CardPayment handleAddPayment={handleAddPayment} />
          </Row>
        )}
        {change > 0 && (
          <Row style={{width: '320px'}}>
            <h5 className="change">
              Change:{' '}
              <strong className="price">
                <Price>{change}</Price>
              </strong>
            </h5>
          </Row>
        )}

        {toCents(remaining) === 0 && bookingMode === 'generate' && (
          <Row style={{width: '320px'}}>
            <Button
              onClick={handleCheckout}
              className="input-checkout bg-success"
              color="success"
              size="sm"
              disabled={disabled}
            >
              DONE
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default Payments;
