import qz from 'qz-tray';
import QzTray from '../Configs/QZTray';
import {getLocalBookingReceipt} from './print';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';

async function printOfflineBooking(
  customerData,
  receiptNotes,
  change,
  booking,
  recentProducts = [],
  branch,
  openDrawer,
  isComplete,
  tableName,
) {
  try {
    let products = recentProducts?.length > 0 ? recentProducts : booking?.booking_products;

    let defaultPrinter = booking?.defaultPrinter;

    let barPrinterProducts = {
      products: [],
      printer: {},
    };
    let kitchenPrinterProducts = {
      products: [],
      printer: {},
    };
    let mainPrinterProducts = {
      products: [],
      printer: {},
    };

    let filterProducts =
      products?.length > 0 &&
      products?.map((product) => {
        if (!isComplete) {
          if (product?.printers?.length > 0) {
            return product?.printers?.map((printer) => {
              if (printer?.printer_type == 'kitchen') {
                kitchenPrinterProducts.products.push(product);
                kitchenPrinterProducts.printer = printer;
              } else if (printer?.printer_type == 'bar') {
                barPrinterProducts.products.push(product);
                barPrinterProducts.printer = printer;
              } else if (printer?.printer_type == 'main') {
                mainPrinterProducts.products.push(product);
                mainPrinterProducts.printer = printer;
              }
            });
          } else {
            mainPrinterProducts.products.push(product);
            mainPrinterProducts.printer = defaultPrinter;
          }
        } else {
          mainPrinterProducts.products.push(product);
          mainPrinterProducts.printer = defaultPrinter;
        }
      });

    // BAR PRINTERS
    if (barPrinterProducts.products?.length > 0) {
      const printString = getLocalBookingReceipt(
        {
          branch_slug: branch?.slug,
          checkout_mode: booking?.booking?.checkout_mode,
          sub_total: booking?.booking?.sub_total_charges,
          discount_amount: booking?.booking?.discount_amount,
          delivery_charges: booking?.booking?.delivery_charges,
          payments: booking?.payment,
          formData: customerData,
          notes: receiptNotes,
          cart: barPrinterProducts.products,
          bookingUnique: uuidv4(),
          syncState: 'syncing',
          checkout_time: moment().format('YY-MM-DD HH:mm'),
          change,
        },
        branch,
        openDrawer,
        barPrinterProducts.printer,
        tableName,
      );
      const prints = barPrinterProducts.printer?.prints || 1;
      for (let j = 0; j < prints; j += 1) {
        const config = await QzTray(barPrinterProducts.printer);
        await qz.print(config, printString);
        await qz.websocket.disconnect();
      }
    }

    // // KITCHEN PRINTERS
    if (kitchenPrinterProducts.products?.length > 0) {
      const printString = getLocalBookingReceipt(
        {
          branch_slug: branch?.slug,
          checkout_mode: booking?.booking?.checkout_mode,
          sub_total: booking?.booking?.sub_total_charges,
          discount_amount: booking?.booking?.discount_amount,
          delivery_charges: booking?.booking?.delivery_charges,
          payments: booking?.payment,
          formData: customerData,
          notes: receiptNotes,
          cart: kitchenPrinterProducts.products,
          bookingUnique: uuidv4(),
          syncState: 'syncing',
          checkout_time: moment().format('YY-MM-DD HH:mm'),
          change,
        },
        branch,
        openDrawer,
        kitchenPrinterProducts.printer,
        tableName,
      );
      const prints = kitchenPrinterProducts.printer?.prints || 1;
      for (let j = 0; j < prints; j += 1) {
        const config = await QzTray(kitchenPrinterProducts.printer);
        await qz.print(config, printString);
        await qz.websocket.disconnect();
      }
    }

    // // MAIN PRINTERS
    if (mainPrinterProducts.products?.length > 0) {
      const printString = getLocalBookingReceipt(
        {
          branch_slug: branch?.slug,
          checkout_mode: booking?.booking?.checkout_mode,
          sub_total: booking?.booking?.sub_total_charges,
          discount_amount: booking?.booking?.discount_amount,
          delivery_charges: booking?.booking?.delivery_charges,
          payments: booking?.payment,
          formData: customerData,
          notes: receiptNotes,
          cart: mainPrinterProducts.products,
          bookingUnique: uuidv4(),
          syncState: 'syncing',
          checkout_time: moment().format('YY-MM-DD HH:mm'),
          change,
        },
        branch,
        openDrawer,
        mainPrinterProducts.printer,
        tableName,
      );

      // console.log(mainPrinterProducts, 'main printers');
      const prints = mainPrinterProducts.printer?.prints || 1;
      for (let j = 0; j < prints; j += 1) {
        const config = await QzTray(mainPrinterProducts.printer);
        await qz.print(config, printString);
        await qz.websocket.disconnect();
      }
    }
  } catch (e) {
    console.log('offline print error', e);
  }
}

export default printOfflineBooking;
