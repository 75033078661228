import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
  SET_SINGE_MENU,
  SET_MENU_CHILDRENS,
  SET_SINGE_SUB_MENU,
  SET_ITEM,
  SET_CART_ITEM,
  SET_CLICKABLE,
} from '../Constants';

export const fetchMenu = () => {
  return {
    type: FETCH_MENU,
  };
};

export const fetchMenuSuccess = (menu) => ({
  type: FETCH_MENU_SUCCESS,
  menu,
});

export const fetchMenuError = (error) => {
  return {
    type: FETCH_MENU_ERROR,
    error,
  };
};

export const setSingleMenu = (selectedMenu) => {
  return {
    type: SET_SINGE_MENU,
    selectedMenu,
  };
};

export const setMenuChildrens = (childrens) => {
  return {
    type: SET_MENU_CHILDRENS,
    childrens,
  };
};

export const setSingleSubMenu = (selectedMenu) => {
  return {
    type: SET_SINGE_SUB_MENU,
    selectedMenu,
  };
};

export const setSelectedItem = (selectedItem) => {
  return {
    type: SET_ITEM,
    selectedItem,
  };
};

export const setCartItem = (index, tier) => {
  return {
    type: SET_CART_ITEM,
    index,
    tier,
  };
};

export const setClickable = (clickable, extraTiers) => ({
  type: SET_CLICKABLE,
  clickable,
  extraTiers,
});
