export const filterMenu = (m, q) => {
  if (q) {
    return m.filter((submenu) => {
      const sub = {
        ...submenu,
        products: submenu.products.filter((product) => {
          return product.title.toLowerCase().includes(q.toLowerCase());
        }),
      };
      return sub.products.length > 0;
    });
  }
  return m;
};

export const searchMenu = (m, q) => {
  if (q) {
    const menu = [];
    m.forEach((submenu) => {
      const sub = {
        ...submenu,
        products: submenu.products.filter((product) => {
          return product.title.toLowerCase().includes(q.toLowerCase());
        }),
      };
      if (sub.products.length > 0) {
        menu.push(sub);
      }
    });
    return menu;
  }
  return m;
};

export const getProductById = (hasChildren, selectedSubMenu, selectedMenu, m, id) => {
  let product = {};

  // for submenu details
  if (hasChildren) {
    for (let i = 0; i < m.length; i += 1) {
      if (m[i]?.id == selectedMenu?.id) {
        if (m[i].childrens) {
          for (let j = 0; j < m[i].childrens.length; j += 1) {
            if (m[i].childrens[j].id === selectedSubMenu?.id) {
              // finding single product
              for (let x = 0; x < m[i].childrens[j].products.length; x += 1) {
                if (m[i].childrens[j].products[x].id === id) {
                  product = m[i].childrens[j].products[x];
                }
              }
            }
          }
        }
      }
    }
  } else {
    for (let i = 0; i < m.length; i += 1) {
      if (m[i].products) {
        for (let j = 0; j < m[i].products.length; j += 1) {
          if (m[i].products[j].id === id) {
            product = m[i].products[j];
          }
        }
      }
    }
  }
  return product;
};
