import {createSelector} from 'reselect';

export const selectOrders = (state) => state.get('bookings');

export const makeSelectBookings = () =>
  createSelector(selectOrders, (OrdersState) => OrdersState.get('bookings').toJS());

export const makeSelectBookingId = () => createSelector(selectOrders, (OrdersState) => OrdersState.get('bookingId'));

export const makeSelectState = () => createSelector(selectOrders, (OrdersState) => OrdersState.get('state'));

export const makeSelectStatus = () => createSelector(selectOrders, (OrdersState) => OrdersState.get('status'));

export const makeSelectOnline = () => createSelector(selectOrders, (OrdersState) => OrdersState.get('online'));
