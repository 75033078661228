// LOCAL SERVER
// const apiUrl = 'http://192.168.18.13:8000/api';

// DEVELOPMENT SERVER
// const apiUrl = 'https://fastfoodbackend.cloudshosting.co.uk/api';

// LIVE SERVER
const apiUrl = 'https://backend.binarypos.co.uk/api';

export default apiUrl;
