import {call, all, put, takeLatest, select} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {ADD_PRINTER, GET_PRINTERS, REMOVE_PRINTER, UPDATE_PRINTER} from './constants';
import {
  addPrinterError,
  addPrinterSuccess,
  getPrintersError,
  getPrintersSuccess,
  removePrinterError,
  removePrinterSuccess,
  updatePrinterError,
  updatePrinterSuccess,
} from './actions';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import AuthorizedDeleteHeaders from '../../Configs/AuthorizedDeleteHeaders';
import AuthorizedPutHeaders from '../../Configs/AuthorizedPutHeaders';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';

export function* getPrinters() {
  const {branch} = yield select(makeSelectHotel());

  const token = yield select(makeSelectToken());

  const branchId = branch?.id;

  const moduleID = branch?.module?.[0]?.id;
  const requestUrl = `${apiUrl}/pos/printers?branch_id=${branchId}`;
  try {
    const {printers} = yield call(apiCall, requestUrl, AuthorizedGetHeaders(token));

    let defaultPrinter = {};
    let otherPrinters = [];

    if (printers?.length) {
      let filterDefault = printers.filter((printer) => printer?.printer_type == 'main');
      let filterOthers = printers.filter((printer) => printer?.printer_type != 'main');

      if (filterDefault?.length) {
        defaultPrinter = filterDefault?.[0];
      }

      if (filterOthers?.length) {
        otherPrinters = filterOthers;
      }
    }

    yield put(getPrintersSuccess(defaultPrinter || {}, otherPrinters));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(getPrintersError(e.message));
  }
}

export function* addPrinter(action) {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());

  const moduleID = branch?.module?.[0]?.id;
  const requestUrl = `${apiUrl}/settings`;
  try {
    const printer = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          module_id: moduleID,
          branch_id: branch.id,
          printer: action.printer,
        },
        token,
      ),
    );
    yield put(addPrinterSuccess(printer, action.isDefault));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(addPrinterError(e.message));
  }
}

export function* updatePrinter(action) {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());

  const moduleID = branch?.module?.[0]?.id;
  const requestUrl = `${apiUrl}/settings/${action.printer.id}`;
  try {
    const printer = yield call(
      apiCall,
      requestUrl,
      AuthorizedPutHeaders(
        {
          branch_id: branch.id,
          module_id: moduleID,
          printer: action.printer,
        },
        token,
      ),
    );
    yield put(updatePrinterSuccess(printer, action.index, action.isDefault));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(updatePrinterError(e.message));
  }
}

export function* removePrinter(action) {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/settings/delete/${action.printerId}`;
  try {
    yield call(
      apiCall,
      requestUrl,
      AuthorizedDeleteHeaders(
        {
          branch_id: branch.id,
        },
        token,
      ),
    );
    yield put(removePrinterSuccess(action.index));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(removePrinterError(e.message));
  }
}

export function* getPrintersSaga() {
  yield takeLatest(GET_PRINTERS, getPrinters);
}

export function* addPrinterSaga() {
  yield takeLatest(ADD_PRINTER, addPrinter);
}

export function* updatePrinterSaga() {
  yield takeLatest(UPDATE_PRINTER, updatePrinter);
}

export function* removePrinterSaga() {
  yield takeLatest(REMOVE_PRINTER, removePrinter);
}

export default function* managementSaga() {
  yield all([getPrintersSaga(), addPrinterSaga(), updatePrinterSaga(), removePrinterSaga()]);
}
