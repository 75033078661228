import React, {useState} from 'react';
import moment from 'moment';
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Delivery from '../../Assets/images/food-delivery.png';
import Takeaway from '../../Assets/images/take-away.png';
import Item from './Item';
import Price from '../../Components/Price';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {updateBookingStatus} from '../Bookings/actions';
import Loadable from '../../Components/Loadable';
import printSyncedBooking from '../../Libs/printSyncedBooking';
import {makeSelectDefaultPrinter, makeSelectPrinters} from '../Management/selectors';
import printOfflineBooking from '../../Libs/printOfflineBooking';

function UpdateSyncedBookingStatus({booking}) {
  const dispatch = useDispatch();
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const printers = useSelector(makeSelectPrinters());
  const {branch} = useSelector(makeSelectHotel());
  const token = useSelector(makeSelectToken());
  const [state, setState] = useState('initial');

  const handleUpdateStatus = async (s) => {
    setState('loading');
    try {
      const response = await apiCall(
        `${apiUrl}/pos/bookings/update`,
        AuthorizedPostHeaders(
          {
            booking_id: booking.booking.id,
            branch_id: branch.id,
            status: s,
          },
          token,
        ),
      );
      if (response.status === s) {
        dispatch(updateBookingStatus(booking.bookingUnique, s));
      }
      setState('success');
    } catch (e) {
      setState('error');
      console.log('error in request', e);
    }
  };
  const handlePrintBooking = async () => {
    try {
      console.log(booking, 'booking');

      await printOfflineBooking(
        booking?.user_address,
        booking?.booking?.notes,
        0,
        {
          ...booking,
          branch_slug: branch.slug,
          checkout_mode: booking?.booking_mode?.mode,
          sub_total: parseInt(booking?.booking?.sub_total_charges),
          discount_amount: booking?.booking?.discount_amount,
          delivery_charges: booking?.booking?.delivery_charges,
          payments: booking?.payment,
          formData: booking?.user_address,
          notes: booking?.booking?.notes ? booking?.booking?.notes : '',
          cart: booking?.booking_products,
          booking_products: booking?.booking_products,
          bookingUnique: booking?.booking?.unique_id,
          syncState: 'syncing',
          checkout_time: booking?.booking?.created_at,
        },
        [],
        branch,
        false,
        false,
      );
      // await printSyncedBooking(booking, branch, false, [defaultPrinter, ...printers]);
    } catch (e) {
      console.log('sync print error', e);
    }
  };
  return (
    <div className="customer-container update-booking">
      <div className="booking-row">
        <div className="checkout-info">
          <div className="img">
            <img src={booking.booking.checkout_mode === 'Delivery' ? Delivery : Takeaway} alt="" />
          </div>
          <div className="body">
            <h3>Requested {booking.booking.checkout_mode === 'Takeaway' ? 'Collection' : 'Delivery'} Timing</h3>
            <p>ASAP ({moment(booking.booking.created_at).format('HH:mm')})</p>
          </div>
        </div>
        <div className="booking-actions">
          <Link to="/orders" className="status-button back">
            Go Back
          </Link>
          <Button className="status-button print" onClick={handlePrintBooking}>
            <span>Print</span>
          </Button>
        </div>
      </div>
      <div className="booking-row">
        <div className="customer-details">
          <h3>Customer Details</h3>
          <p className="name">
            <strong>{booking.user_address?.name}</strong>
          </p>
          <p className="telephone">{booking.user_address?.telephone}</p>
          <p className="address">{booking.user_address.address}</p>
          <p className="postcode">{booking.user_address.postcode}</p>
        </div>
        <div className="status-actions">
          <h4>
            Status:&nbsp;{' '}
            <span className={`status ${booking.booking.status.toLowerCase()}`}>
              <Loadable active={state === 'loading'}>{booking.booking.status}</Loadable>
            </span>
          </h4>
          <div className="actions">
            <Button className="status-button cancel" onClick={() => handleUpdateStatus('Cancelled')}>
              <span>Cancel</span>
            </Button>
            <Button className="status-button refund" onClick={() => handleUpdateStatus('Refunded')}>
              <span>Refund</span>
            </Button>
            <Button className="status-button complete" onClick={() => handleUpdateStatus('Completed')}>
              <span>Complete</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="notes">
        <h3>Restaurant Notes</h3>
        <blockquote>{booking.booking.notes}</blockquote>
      </div>
      <div className="products-wrapper">
        <h3>Order # {booking.booking.unique}</h3>
        {booking.booking_products.map((product, i) => (
          <Item product={product} key={`${product.id}${i}`} mode={booking.booking.checkout_mode} />
        ))}
      </div>
      <div className="booking-summary">
        <div className="summary-item">
          <div className="label">Subtotal</div>
          <div className="value">
            <Price>{booking.booking.sub_total_charges}</Price>
          </div>
        </div>
        <div className="summary-item">
          <div className="label">Delivery Charges</div>
          <div className="value">
            <Price>{booking.booking.delivery_charges}</Price>
          </div>
        </div>
        <div className="summary-item">
          <div className="label">Discount</div>
          <div className="value">
            <Price>{booking.booking.discount_amount || 0}</Price>
          </div>
        </div>
        <div className="summary-item">
          <div className="label">Payable</div>
          <div className="value">
            <Price>{booking.booking.total_charges}</Price>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSyncedBookingStatus;
