import React, {useEffect, useState} from 'react';
import {useHistory, Redirect} from 'react-router-dom';
import {Button, Col, FormGroup, Row, Label, Alert} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {addCustomer, saveOperation, selectItem} from '../../Components/Cart/actions';
import {makeSelectCheckoutMode, makeSelectCustomer} from '../../Components/Cart/selectors';
import {makeSelectCustomer as makeSelectDbCustomer, makeSelectCustomerNumber} from './selectors';
import TextInput from '../../Components/TextInput';
import Addresses from '../../Components/Addresses';
import Master from '../Master';
import Loadable from '../../Components/Loadable';
import validateCustomer from '../../Libs/validateCustomer';
import DbCustomer from './DbCustomer';
import {makeSelectClockedIn, makeSelectHotel, makeSelectToken, makeSelectWalkInUser} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {deformatTelephoneNumber, reformatTelephoneNumber, stripZero} from '../../Libs/object';
import {findCustomer, findCustomerSuccess} from './actions';
import CustomerNotes from './CustomerNotes';

function Customer() {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const customer = useSelector(makeSelectCustomer());
  const walkInUser = useSelector(makeSelectWalkInUser());
  const dbCustomer = useSelector(makeSelectDbCustomer());
  const customerNumber = useSelector(makeSelectCustomerNumber());
  const clockedIn = useSelector(makeSelectClockedIn());
  const checkoutMode = useSelector(makeSelectCheckoutMode());
  const [mode, setMode] = useState('customer');
  const [state, setState] = useState('initial');
  const [validation, setValidation] = useState('validated');
  const [addresses, setAddresses] = useState([]);
  const [name, setName] = useState('');
  const [postcode, setPostcode] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchQ, setSearchQ] = useState('');

  useEffect(() => {
    dispatch(selectItem(-1));
  });

  const fetchDeliveryOperations = async (pc) => {
    apiCall(`${apiUrl}/pos/delivery-charges/${pc}/${branch.id}`, AuthorizedGetHeaders(token))
      .then((operation) => {
        dispatch(saveOperation(operation));
      })
      .catch((e) => {
        dispatch(saveOperation({}));
        console.log('delivery charges error', e);
      });
  };

  const handleAddCustomer = () => {
    let details = {};
    if (checkoutMode === 'Delivery') {
      details = {
        name: name.trim().length > 0 ? name : walkInUser.name,
        postcode,
        telephone,
        address,
      };
    } else {
      details = {
        name: name.trim().length > 0 ? name : walkInUser.name,
        postcode: walkInUser.postcode,
        telephone: telephone.trim().length > 2 ? telephone : deformatTelephoneNumber(walkInUser.telephone),
        address: walkInUser.address,
      };
    }
    const validated = validateCustomer(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(addCustomer(details));
      push('/dashboard');
    }
  };

  const selectCustomer = (c) => {
    setTelephone(deformatTelephoneNumber(c.telephone) || '');
    setName(c.name || '');
    setPostcode(c.postcode || '');
    setAddress(c.address || '');
    fetchDeliveryOperations(c.postcode);
  };

  const handleClearCustomer = () => {
    setName('');
    setPostcode('');
    setAddress('');
    setTelephone('');
    dispatch(saveOperation({}));
    dispatch(findCustomerSuccess({}, [], []));
    dispatch(addCustomer({}));
  };

  const searchPostCode = async () => {
    setAddresses([]);
    setMode('addresses');
    setState('loading');
    try {
      const response = await fetch(`https://api.getaddress.io/find/${postcode}?api-key=s5r1ZZOZ-kuzBUQ9n_yZqw29840`);
      const json = await response.json();
      if (json.addresses && json.addresses?.length) {
        setState('success');
        setAddresses(json?.addresses);
      } else {
        setState('error');
      }
    } catch (e) {
      setState('error');
      console.log('post code search error', e);
    }
  };

  const searchCustomer = (q, type) => {
    setSearchQ(q);
    setSearchType(type);
    setMode('customer');
    dispatch(findCustomer(stripZero(q)));
  };

  const handleSetAddress = (a) => {
    setAddress(a);
    fetchDeliveryOperations(postcode);
  };

  useEffect(() => {
    setName(customer.name || '');
    setPostcode(customer.postcode || '');
    setTelephone(deformatTelephoneNumber(customer.telephone || `${customerNumber || ''}` || ''));
    setAddress(customer.address || '');
    if (searchType === 'name') {
      setName(searchQ);
    }
    if (searchType === 'telephone') {
      setTelephone(deformatTelephoneNumber(searchQ));
    }
  }, [customer.address, customer.name, customer.postcode, customer.telephone, dbCustomer.from, customerNumber]);
  if (!clockedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Master>
      <div className="container-fluid customer-container">
        <Row>
          <Col md="6">
            <Row>
              <Col md="12">
                <FormGroup className="m-0">
                  <Label>CONTACT #</Label>
                  <div className="postcode-finder">
                    <TextInput
                      autoComplete="off"
                      inputClassName="form-control"
                      type="text"
                      value={telephone}
                      onChange={(value) => setTelephone(value)}
                      placeholder="Enter Mobile Number"
                    />
                    <Button className="search-postcode bg-dark" onClick={() => searchCustomer(telephone, 'telephone')}>
                      <Loadable active={state === 'loading' && mode === 'customer'} color="white">
                        SEARCH
                      </Loadable>
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {checkoutMode === 'Delivery' ? (
              <Row>
                <Col md="12">
                  <FormGroup className="m-0">
                    <Label>POSTCODE</Label>
                    <div className="postcode-finder">
                      <TextInput
                        autoComplete="off"
                        inputClassName="form-control"
                        type="text"
                        value={postcode}
                        onChange={(value) => setPostcode(value)}
                        placeholder="Enter Postcode"
                      />
                      <Button className="search-postcode bg-dark" onClick={searchPostCode}>
                        <Loadable active={state === 'loading' && mode === 'addresses'} color="white">
                          SEARCH
                        </Loadable>
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md="12">
                <FormGroup className="m-0">
                  <Label>FULL NAME</Label>
                  <div className="postcode-finder">
                    <TextInput
                      autoComplete="off"
                      inputClassName="form-control"
                      type="text"
                      value={name}
                      onChange={(value) => setName(value)}
                      placeholder="Full Name"
                    />
                    <Button className="search-postcode bg-dark" onClick={() => searchCustomer(name, 'name')}>
                      <Loadable active={state === 'loading' && mode === 'customer'} color="white">
                        SEARCH
                      </Loadable>
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {checkoutMode === 'Delivery' ? (
              <Row>
                <Col md="12">
                  <FormGroup className="m-0">
                    <Label>ADDRESS</Label>
                    <TextInput
                      autoComplete="off"
                      inputClassName="form-control"
                      type="text"
                      value={address}
                      onChange={(value) => setAddress(value)}
                      placeholder="Enter Full Address"
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            <Row className="p-3">{validation !== 'validated' && <Alert color="danger">{validation}</Alert>}</Row>
            <Row className="customer-actions">
              <button type="button" className="customer-btn" onClick={handleAddCustomer}>
                ADD CUSTOMER
              </button>
              <button type="button" className="clear-btn" onClick={handleClearCustomer}>
                CLEAR
              </button>
            </Row>
          </Col>
          <Col md="6">
            <Row className="address-section">
              <Addresses
                state={state}
                selectedAddress={address}
                addresses={addresses}
                setAddress={handleSetAddress}
                mode={mode}
              />
              <DbCustomer mode={mode} customer={dbCustomer} selectCustomer={selectCustomer} />
            </Row>
            <Row className="notes-section">
              <CustomerNotes />
            </Row>
          </Col>
        </Row>
      </div>
    </Master>
  );
}

export default Customer;
