import React, {useState} from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Alert from 'reactstrap/es/Alert';
import SelectedNotes from '../Notes/SelectedNotes';
import ManageNotes from '../Notes/ManageNotes';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectToken} from '../Authentication/selectors';
import Loadable from '../../Components/Loadable';

function AddNotes() {
  const {push} = useHistory();
  const {id} = useParams();
  const token = useSelector(makeSelectToken());
  const [notes, setNotes] = useState([]);
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const handleSaveNote = (n) => {
    setNotes([...notes, n]);
  };
  const handleUnSaveNote = (index) => {
    setNotes(notes.filter((note, i) => index !== i));
  };
  const handleAssignNotes = () => {
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/pos/users/assignNotes`,
      AuthorizedPostHeaders(
        {
          user_id: id,
          notes,
        },
        token,
      ),
    )
      .then((response) => {
        setState('success');
        push('/');
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        console.log('error', e);
      });
  };
  return (
    <div className="customer-container notes">
      <Row>
        <Col className="p-0">
          <SelectedNotes
            mode="customer"
            notes={notes}
            handleSaveNote={handleSaveNote}
            handleUnSaveNote={handleUnSaveNote}
          />
        </Col>
        <Col className="p-0">
          <ManageNotes mode="customer" handleSaveNote={handleSaveNote} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-content-center">
        {state === 'error' && <Alert color="danger">{status}</Alert>}
        <button className="add-notes-btn" type="button" onClick={handleAssignNotes}>
          <Loadable active={state === 'loading'} color="white">
            ADD NOTES
          </Loadable>
        </button>
      </Row>
    </div>
  );
}

export default AddNotes;
