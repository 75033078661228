import React, {useEffect, useState} from 'react';
import {Button, Alert} from 'reactstrap';
import {useHistory, Redirect} from 'react-router-dom';
import Form from 'reactstrap/es/Form';
import Row from 'reactstrap/es/Row';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import FormGroup from 'reactstrap/es/FormGroup';
import Label from 'reactstrap/es/Label';
import Input from 'reactstrap/es/Input';
import {clearCart, selectItem} from '../../Components/Cart/actions';
import Master from '../Master';
import Payments from './Payments';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import printOfflineBooking from '../../Libs/printOfflineBooking';
import {makeSelectDefaultPrinter, makeSelectPrinters} from '../Management/selectors';
import {
  makeSelectCheckoutMode,
  makeSelectCustomer,
  makeSelectDiscount,
  makeSelectProducts,
  makeSelectSelectedNotes,
} from '../../Components/Cart/selectors';
import {cartTotal, getDiscountPrice, getMinOrderAmount} from '../../Libs/prices';
import {checkIfObjectEmpty} from '../../Libs/object';
import {makeSelectChange, makeSelectPayments} from './selectors';
import openTill from '../../Libs/openTill';
import Price from '../../Components/Price';
import {makeSelectBookingId, makeSelectBookings} from '../Bookings/selectors';
import {bookingByUnique} from '../../Libs/bookings';
import {fetchBookings} from '../Bookings/actions';

function Checkout() {
  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const printers = useSelector(makeSelectPrinters());
  const mode = useSelector(makeSelectCheckoutMode());
  const payments = useSelector(makeSelectPayments());
  const notes = useSelector(makeSelectSelectedNotes());
  const products = useSelector(makeSelectProducts());
  const booking = useSelector(makeSelectBookings());
  const bookingId = useSelector(makeSelectBookingId());
  const customer = useSelector(makeSelectCustomer());
  const discount = useSelector(makeSelectDiscount());
  const change = useSelector(makeSelectChange());
  const minOrderAmount = getMinOrderAmount(branch, mode);
  const cartTotalAmount = cartTotal(products, mode);
  const subTotal = minOrderAmount > cartTotalAmount ? minOrderAmount : cartTotalAmount;
  const discountPrice = getDiscountPrice(discount, subTotal);
  const {push} = useHistory();
  const [state, setState] = useState('initial');
  const [receipt, setReceipt] = useState(true);
  // console.log(bookingData, 'single booking');
  useEffect(() => {
    dispatch(selectItem(-1));
  });

  const done = async () => {
    dispatch(clearCart());
    if (receipt) {
      const customerData = checkIfObjectEmpty(customer) ? branch.walk_in_user?.walk_in_user_address : customer;
      let receiptNotes = notes.length ? notes.join(', ') : '';
      // console.log(booking, bookingId, 'booking ID');
      let filterBookingByBookingId = booking?.length > 0 && booking.filter((row) => row?.booking?.id == bookingId);
      // console.log(filterBookingByBookingId, 'booking filter in checkout');

      // return;
      await printOfflineBooking(
        customerData,
        receiptNotes,
        change,
        filterBookingByBookingId?.length > 0 ? filterBookingByBookingId?.[0] : {},
        [],
        branch,
        true,
        false,
      );
    } else {
      await openTill(defaultPrinter);
    }
    push('/');
  };

  if (token.length === 0) {
    return <Redirect to="/login" />;
  }

  if (state === 'success') {
    return (
      <Master>
        <div className="container-fluid customer-container">
          <Row className="response">
            <Alert color="success">Successfully Created booking</Alert>
            {change > 0 && (
              <Row style={{width: '320px', margin: '15px'}}>
                <h5 className="change">
                  Change:{' '}
                  <strong className="price">
                    <Price>{change}</Price>
                  </strong>
                </h5>
              </Row>
            )}
            <FormGroup check>
              <Label check>
                <Input type="checkbox" defaultChecked={receipt} onChange={() => setReceipt(!receipt)} /> Print Receipt
              </Label>
            </FormGroup>
          </Row>
          <Row className="justify-content-center">
            <Button className="w-25 p-4 btn btn-secondary" color="secondary" onClick={done}>
              Done
            </Button>
          </Row>
        </div>
      </Master>
    );
  }

  return (
    <Master>
      <div className="container-fluid customer-container">
        <Form onSubmit={(e) => e.preventDefault()} className="checkout-form">
          <Payments setState={setState} state={state} />
        </Form>
      </div>
    </Master>
  );
}

export default Checkout;
