import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {getFloors, getTables, resetTables, setTableId} from './actions';
import {makeSelectFloors, makeSelectTables} from './selectors';
import '../../../src/Assets/styles/dine-in.scss';
import history from '../../Libs/history';

const DineInPage = () => {
  const dispatch = useDispatch();
  const floors = useSelector(makeSelectFloors());
  const tables = useSelector(makeSelectTables());
  React.useEffect(() => {
    dispatch(getFloors());
    dispatch(resetTables());
  }, []);

  const [selectedFloor, setSelectedFloor] = useState();
  const [selectedTable, setSelectedTable] = useState(null);

  const handleFloorChange = (floorId) => {
    setSelectedFloor(floorId);
    if (floorId) {
      dispatch(getTables(floorId));
    }
  };

  const handleTableClick = (tableId) => {
    setSelectedTable(tableId);
    dispatch(setTableId(tableId));
    history.push('/dashboard');
  };

  return (
    <div className="dine-in">
      <div className="dine-in-page">
        <div className="floor-selector">
          {floors?.length > 0 &&
            floors?.map((floor) => {
              return (
                <button
                  style={{fontSize: 12}}
                  onClick={() => handleFloorChange(floor?.id)}
                  className={floor?.id == selectedFloor ? 'selected-floor' : 'floor'}
                >
                  {floor?.name}
                </button>
              );
            })}
        </div>
        <div className="grid-container">
          {tables?.length > 0 &&
            tables?.map((table, index) => (
              <div
                // className={`box ${isTableSelected(tableNumber) ? 'selected' : ''}`}
                className={`box ${table?.id == selectedTable ? 'selected' : ''} ${table?.reserved ? 'selected' : ''}`}
                key={index}
                onClick={() => handleTableClick(table?.id)}
              >
                <p style={{fontSize: 12, fontWeight: 'bold'}}>{table?.name}</p>
                {table?.reserved ? <p>(closed)</p> : <p>(open)</p>}
              </div>
            ))}
        </div>
      </div>
      <div className="dine-btn">
        <Link to="/">
          <button className="back-button">Back</button>
        </Link>
        <Link to="/dine-in">
          <button className="back-button">Dine-In</button>
        </Link>
        <Link to="/customer">
          <button className="back-button">Collection</button>
        </Link>
        <Link to="/customer">
          <button className="back-button">Delivery</button>
        </Link>
        <Link to="/management">
          <button className="back-button ">Management</button>
        </Link>
        <Link to="/clock-out">
          <button className="back-button ">Shut Down</button>
        </Link>
      </div>
    </div>
  );
};

export default DineInPage;
