import React, {useState} from 'react';
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {useSelector} from 'react-redux';
import {Redirect, useHistory} from 'react-router-dom';
import Alert from 'reactstrap/es/Alert';
import Vendors from './Vendors';
import {checkIfObjectEmpty} from '../../Libs/object';
import TextInput from '../../Components/TextInput';
import Loadable from '../../Components/Loadable';
import Addresses from '../../Components/Addresses';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectHotel, makeSelectToken, makeSelectUserPermissions} from '../Authentication/selectors';
import {toCents} from '../../Libs/prices';
import validateTask from '../../Libs/validateTask';
import Loader from '../../Components/Loader';

function Create() {
  const {push} = useHistory();
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const permissions = useSelector(makeSelectUserPermissions());
  const [vendor, setVendor] = useState({});
  const [checkoutMode, setCheckoutMode] = useState('');
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const [mode, setMode] = useState('initial');
  const [validation, setValidation] = useState('validated');
  const [addresses, setAddresses] = useState([]);
  const [ref, setRef] = useState('');
  const [postcode, setPostcode] = useState('');
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');

  const handleCreateTask = (payment) => {
    const details = {
      branch_id: branch.id,
      vendor_id: vendor.id,
      checkout_mode: checkoutMode.toLowerCase(),
      reference_no: ref,
      address: address.length > 0 ? address : postcode,
      postcode,
      status: 'incomplete',
      total_charges: toCents(amount),
      payment_method: payment,
    };
    const validated = validateTask(details);
    if (validated !== 'validated') {
      setValidation(validated);
      return;
    }
    setState('loading');
    setMode('create');
    setStatus('');
    apiCall(`${apiUrl}/tasks/generateTask`, AuthorizedPostHeaders(details, token))
      .then(() => {
        setState('success');
        push('/');
      })
      .catch((e) => {
        // console.log('error', e);
        setState('error');
        setStatus(e.message);
      });
  };

  const searchPostCode = async () => {
    setAddresses([]);
    setState('loading');
    setMode('addresses');
    fetch(`https://api.getaddress.io/find/${postcode}?api-key=s5r1ZZOZ-kuzBUQ9n_yZqw29840`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw Error(response.statusText);
      })
      .then((json) => {
        if (json.addresses && json.addresses?.length) {
          setState('success');
          setAddresses(json?.addresses);
        }
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        // console.log('post code search error', e);
      });
  };

  if (!permissions.tasks_access === true) {
    return <Redirect to="/" />;
  }
  if (mode === 'create' && state === 'loading') {
    return (
      <div className="create">
        <Loader color="black" />
      </div>
    );
  }
  return (
    <div className="create">
      {checkIfObjectEmpty(vendor) ? (
        <Vendors setVendor={setVendor} />
      ) : (
        <div className="create-task">
          <form>
            <Row>
              <button className="btn-info" type="button">
                {vendor.title}
              </button>
              <button
                className={`checkout-mode ${checkoutMode === 'Takeaway' ? 'selected' : ''}`}
                type="button"
                onClick={() => setCheckoutMode('Takeaway')}
              >
                COLLECTION
              </button>
              <button
                className={`checkout-mode ${checkoutMode === 'Delivery' ? 'selected' : ''}`}
                type="button"
                onClick={() => setCheckoutMode('Delivery')}
              >
                DELIVERY
              </button>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="m-0">
                  <Label>REF</Label>
                  <TextInput
                    autoComplete="off"
                    inputClassName="form-control"
                    type="text"
                    value={ref}
                    onChange={(value) => setRef(value)}
                    placeholder="Enter Reference Number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="m-0">
                  <Label>ADDRESS</Label>
                  <div className="postcode-finder">
                    <TextInput
                      autoComplete="off"
                      inputClassName="form-control"
                      type="text"
                      value={postcode}
                      onChange={(value) => setPostcode(value)}
                      placeholder="Enter Postcode"
                    />
                    <Button className="search-postcode bg-dark" onClick={searchPostCode}>
                      <Loadable active={state === 'loading' && mode === 'addresses'} color="white">
                        POSTCODE
                      </Loadable>
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <FormGroup className="m-0">
                  <Label>AMOUNT</Label>
                  <TextInput
                    autoComplete="off"
                    inputClassName="form-control"
                    type="text"
                    value={amount}
                    onChange={(value) => setAmount(value)}
                    placeholder="Enter Amount"
                  />
                </FormGroup>
              </Col>
            </Row>
            {state === 'error' && (
              <Row>
                <Alert className="m-3" color="danger">
                  {status}
                </Alert>
              </Row>
            )}
            {validation !== 'validated' && (
              <Row>
                <Alert className="m-3" color="danger">
                  {validation}
                </Alert>
              </Row>
            )}
            <Row className="task-actions">
              <button type="button" onClick={() => handleCreateTask('cash')}>
                CASH
              </button>
              <button type="button" onClick={() => handleCreateTask('card')}>
                CARD
              </button>
            </Row>
          </form>
          <div className="w-50">
            <Row>
              <button className="btn-primary" type="button" onClick={() => setVendor({})}>
                Go Back
              </button>
            </Row>
            <Addresses
              mode="addresses"
              state={state}
              setAddress={setAddress}
              addresses={addresses}
              selectedAddress={address}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Create;
