export const GET_FLOOR = 'Favorite/DineIn/GET_FLOOR';

export const GET_FLOOR_SUCCESS = 'Favorite/DineIn/GET_FLOOR_SUCCESS';

export const GET_FLOOR_ERROR = 'Favorite/DineIn/GET_FLOOR_ERROR';

// GET TABLES

export const GET_TABLES = 'Favorite/DineIn/GET_TABLES';

export const RESET_TABLES = 'Favorite/DineIn/RESET_TABLES';

export const GET_TABLES_SUCCESS = 'Favorite/DineIn/GET_TABLES_SUCCESS';

export const GET_TABLES_ERROR = 'Favorite/DineIn/GET_TABLES_ERROR';

export const SET_TABLE_ID = 'Favorite/DineIn/SET_TABLE_ID';
